var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("general.logo")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", { attrs: { menu: "logo" } }),
                _vm._v(" "),
                _c("div", { staticClass: "col-10 col-lg-10 col-md-10" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-6 col-md-6" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.trans("general.logo_type", {
                                type: _vm.trans("general.sidebar")
                              })
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("upload-image", {
                          attrs: {
                            id: "sidebar_logo",
                            "upload-path": "/configuration/logo/sidebar",
                            "remove-path": "/configuration/logo/sidebar/remove",
                            "image-source": _vm.logo.sidebar
                          },
                          on: {
                            uploaded: _vm.updateSidebarLogo,
                            removed: _vm.updateSidebarLogo
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-6 col-md-6" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.trans("general.logo_type", {
                                type: _vm.trans("general.main")
                              })
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("upload-image", {
                          attrs: {
                            id: "logo",
                            "upload-path": "/configuration/logo/main",
                            "remove-path": "/configuration/logo/main/remove",
                            "image-source": _vm.logo.main
                          },
                          on: {
                            uploaded: _vm.updateMainLogo,
                            removed: _vm.updateMainLogo
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }