var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "main-wrapper" } },
    [
      _c("app-header"),
      _vm._v(" "),
      _c("app-sidebar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-wrapper" },
        [
          _c(
            "div",
            { staticClass: "container-fluid" },
            [
              !_vm.getConfig("mode")
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })
                : _vm._e(),
              _vm._v(" "),
              _c("router-view"),
              _vm._v(" "),
              _c("app-right-sidebar")
            ],
            1
          ),
          _vm._v(" "),
          _c("app-footer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }