var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade sub-task-detail",
      staticStyle: { display: "none" },
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "subTaskDetail",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h4",
              { staticClass: "modal-title", attrs: { id: "subTaskDetail" } },
              [_vm._v(_vm._s(_vm.sub_task.title) + "\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-hidden": "true"
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm.sub_task.user_added
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-3 col-xs-6 b-r" }, [
                    _c("div", { staticClass: "user-profile pull-right" }, [
                      _c("div", { staticClass: "profile-img" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.getAvatar(_vm.sub_task.user_added),
                            alt: "user"
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(_vm.trans("task.owner")))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v(
                        _vm._s(
                          _vm.sub_task.user_added.profile.first_name +
                            " " +
                            _vm.sub_task.user_added.profile.last_name
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-3 col-xs-6 b-r" },
                    [
                      _vm.sub_task.status
                        ? [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.trans("task.completed_at")))
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-muted" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("momentDateTime")(
                                    _vm.sub_task.completed_at
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "confirm",
                                    rawName: "v-confirm",
                                    value: {
                                      ok: _vm.confirmToggleStatus(_vm.sub_task)
                                    },
                                    expression:
                                      "{ok: confirmToggleStatus(sub_task)}"
                                  }
                                ],
                                key: _vm.sub_task.id,
                                staticClass: "btn btn-danger btn-xs"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("task.mark_as_incomplete"))
                                )
                              ]
                            )
                          ]
                        : [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "confirm",
                                    rawName: "v-confirm",
                                    value: {
                                      ok: _vm.confirmToggleStatus(_vm.sub_task)
                                    },
                                    expression:
                                      "{ok: confirmToggleStatus(sub_task)}"
                                  }
                                ],
                                key: _vm.sub_task.id,
                                staticClass: "btn btn-success btn-xs"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("task.mark_as_complete"))
                                )
                              ]
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3 col-xs-6 b-r" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.trans("task.created_at")))
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("momentDateTime")(_vm.sub_task.created_at)
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.trans("task.updated_at")))
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("momentDateTime")(_vm.sub_task.updated_at)
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3 col-xs-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ribbon-wrapper card",
                        staticStyle: { "margin-right": "-15px" }
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "ribbon",
                              "ribbon-" +
                                _vm.getSubTaskStatus(_vm.sub_task).color,
                              "ribbon-right"
                            ]
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.getSubTaskStatus(_vm.sub_task).label)
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", {
              staticClass: "m-t-20",
              domProps: { innerHTML: _vm._s(_vm.sub_task.description) }
            }),
            _vm._v(" "),
            _vm.attachments.length
              ? _c("div", [
                  _c(
                    "ul",
                    {
                      staticClass: "m-t-10",
                      staticStyle: { "list-style": "none", padding: "0" }
                    },
                    _vm._l(_vm.attachments, function(attachment) {
                      return _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/task/" +
                                _vm.sub_task.task.uuid +
                                "/sub-task/" +
                                _vm.sub_task.uuid +
                                "/attachment/" +
                                attachment.uuid +
                                "/download?token=" +
                                _vm.authToken
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-paperclip" }),
                            _vm._v(" " + _vm._s(attachment.user_filename))
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("p", [
              _c("i", { staticClass: "far fa-clock" }),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  _vm._s(_vm.trans("task.updated_at")) +
                    " " +
                    _vm._s(_vm._f("momentDateTime")(_vm.sub_task.updated_at))
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }