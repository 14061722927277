var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v("Configuration")
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [_c("router-link", { attrs: { to: "/home" } }, [_vm._v("Home")])],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v("Configuration")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/locale" } }, [
                _vm._v("Locale")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("Translation")
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-2 col-lg-2 col-md-2" }, [
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.modules, function(mdl) {
                    return _c(
                      "li",
                      {
                        class: [
                          mdl === _vm.module ? "active" : "",
                          "list-group-item"
                        ]
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: _vm.getModuleLink(mdl), exact: "" } },
                          [_vm._v(_vm._s(_vm.getName(mdl)))]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-10 col-lg-10 col-md-10" },
                [
                  _c("show-tip", {
                    attrs: { module: "locale", tip: "tip_translation" }
                  }),
                  _vm._v(" "),
                  _vm.getWordCount
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.words, function(word, index) {
                            return _c("div", [
                              typeof word === "object"
                                ? _c(
                                    "div",
                                    _vm._l(word, function(wrd, i) {
                                      return _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  _vm.module +
                                                    "." +
                                                    index +
                                                    "." +
                                                    i
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.words[index][i],
                                                expression: "words[index][i]"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: index + "_" + i
                                            },
                                            domProps: {
                                              value: _vm.words[index][i]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.words[index],
                                                  i,
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _c("div", { staticClass: "form-group" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(_vm.module + "." + index)
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.words[index],
                                          expression: "words[index]"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        value: "",
                                        name: index
                                      },
                                      domProps: { value: _vm.words[index] },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.words,
                                            index,
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                            ])
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-info btn-sm pull-right",
                                on: { click: _vm.saveTranslation }
                              },
                              [_vm._v("Save")]
                            )
                          ])
                        ],
                        2
                      )
                    : _c("div", [
                        _c("p", { staticClass: "alert alert-danger" }, [
                          _vm._v("No record found!")
                        ])
                      ])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }