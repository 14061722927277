var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            },
            keydown: function($event) {
              return _vm.taskCommentForm.errors.clear($event.target.name)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.taskCommentForm.comment,
                    expression: "taskCommentForm.comment"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "comment",
                  placeholder:
                    _vm.type === "reply"
                      ? _vm.trans("task.type_comment", {
                          type: _vm.trans("task.reply")
                        })
                      : _vm.trans("task.type_comment", {
                          type: _vm.trans("task.comment")
                        })
                },
                domProps: { value: _vm.taskCommentForm.comment },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.taskCommentForm,
                      "comment",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.taskCommentForm,
                  "prop-name": "comment"
                }
              })
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }