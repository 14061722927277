var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _vm.product.name
      ? _c("table", { staticClass: "table" }, [
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("Product Name")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.product.name))])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Current Version")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.product.current_version))])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Latest Version")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.product.latest_version) +
                    "\n                        "
                ),
                _vm.product.current_version != _vm.product.latest_version &&
                !_vm.update
                  ? _c(
                      "span",
                      [
                        _c("br"),
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-info btn-sm",
                            attrs: { to: "/update" }
                          },
                          [_vm._v("Update Available")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.current_version == _vm.product.latest_version
                  ? _c("span", { staticClass: "btn btn-success btn-sm" }, [
                      _vm._v("Up-to-date")
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Latest Version Release")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm._f("moment")(_vm.product.latest_version_release))
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Purchase Code")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.product.purchase_code))])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Registered Email Id")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.product.email))])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("License Type")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.product.license_type))])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Date of Purchase")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm._f("moment")(_vm.product.date_of_purchase)))
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _vm._v("Support Validity "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-info btn-sm",
                    attrs: {
                      href:
                        "http://codecanyon.net/item/x/" +
                        _vm.product.envato_code +
                        "?=ScriptMint",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Renew Support")]
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm._f("moment")(_vm.product.date_of_support_expiry)) +
                    " "
                ),
                _c("br"),
                _vm._v(" "),
                _vm.checkSupportValidity
                  ? _c("span", { staticClass: "label label-success" }, [
                      _vm._v("Supported")
                    ])
                  : _c("span", { staticClass: "label label-danger" }, [
                      _vm._v("Expired")
                    ])
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Access Code")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.product.access_code))])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Checksum")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.product.checksum))])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }