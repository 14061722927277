var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [
        _c("small", [
          _vm._v(
            _vm._s(_vm.trans("task.rating_type")) +
              ": " +
              _vm._s(_vm.trans("task." + _vm.task.rating_type + "_rating"))
          )
        ])
      ]),
      _vm._v(" "),
      _vm.task.rating_type === "task_based" && _vm.task.user.length
        ? [
            _c("show-tip", {
              attrs: { module: "task", tip: "tip_task_rating_task_based" }
            }),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submitTaskRating($event)
                  },
                  keydown: function($event) {
                    return _vm.taskRatingForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _vm._l(_vm.taskRatingForm.row, function(row) {
                  return _c("div", { staticClass: "row m-b-10" }, [
                    _c("div", { staticClass: "col-6 col-md-4" }, [
                      _c("div", { staticClass: "d-flex flex-row" }, [
                        _c("div", {}, [
                          _c("img", {
                            staticClass: "img-circle",
                            attrs: {
                              src: _vm.getAvatar(row.user),
                              alt: "user",
                              width: "70"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-l-20" }, [
                          _c("h4", { staticClass: "font-medium" }, [
                            _vm._v(
                              _vm._s(
                                row.user.profile.first_name +
                                  " " +
                                  row.user.profile.last_name
                              )
                            )
                          ]),
                          _vm._v(" "),
                          row.user.profile.designation
                            ? _c("h6", [
                                _vm._v(
                                  _vm._s(
                                    row.user.profile.designation.name +
                                      " " +
                                      _vm.trans("general.in") +
                                      " " +
                                      row.user.profile.designation.department
                                        .name
                                  )
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 col-md-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: row.rating,
                                expression: "row.rating"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "rating",
                              placeholder: _vm.trans("task.rating")
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  row,
                                  "rating",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "", value: "" } },
                              [_vm._v(_vm._s(_vm.trans("task.rating")))]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "1" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("task.rating_star", { star: 1 })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "2" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("task.rating_star", { star: 2 })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "3" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("task.rating_star", { star: 3 })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "4" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("task.rating_star", { star: 4 })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "5" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("task.rating_star", { star: 5 })
                                )
                              )
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("autosize-textarea", {
                            staticClass: "form-control",
                            attrs: {
                              row: "2",
                              placeholder: _vm.trans("task.rating_remarks"),
                              name: "remarks"
                            },
                            model: {
                              value: row.remarks,
                              callback: function($$v) {
                                _vm.$set(row, "remarks", $$v)
                              },
                              expression: "row.remarks"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-info waves-effect waves-light pull-right",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.save")))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "clearfix" })
              ],
              2
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.task.rating_type === "sub_task_based"
        ? [
            _c("show-tip", {
              attrs: { module: "task", tip: "tip_task_rating_sub_task_based" }
            }),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submitSubTaskRating($event)
                  },
                  keydown: function($event) {
                    return _vm.subTaskRatingForm.errors.clear(
                      $event.target.name
                    )
                  }
                }
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v(_vm._s(_vm.trans("user.select_user")))]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.subTaskRatingForm.user_id,
                          expression: "subTaskRatingForm.user_id"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "user_id",
                        placeholder: _vm.trans("user.select_user")
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.subTaskRatingForm,
                              "user_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.updateSubTaskRating
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "" } }, [
                        _vm._v(_vm._s(_vm.trans("user.select_user")))
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.task.user, function(user) {
                        return _c("option", { domProps: { value: user.id } }, [
                          _vm._v(
                            _vm._s(
                              user.profile.first_name +
                                " " +
                                user.profile.last_name
                            )
                          )
                        ])
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _vm.subTaskRatingForm.user_id
                  ? [
                      _vm._l(_vm.subTaskRatingForm.row, function(row) {
                        return _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6 col-md-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v(_vm._s(row.sub_task.title))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 col-md-2" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row.rating,
                                      expression: "row.rating"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    name: "rating",
                                    placeholder: _vm.trans("task.rating")
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        row,
                                        "rating",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { disabled: "", value: "" } },
                                    [_vm._v(_vm._s(_vm.trans("task.rating")))]
                                  ),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "1" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("task.rating_star", {
                                          star: 1
                                        })
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "2" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("task.rating_star", {
                                          star: 2
                                        })
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "3" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("task.rating_star", {
                                          star: 3
                                        })
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "4" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("task.rating_star", {
                                          star: 4
                                        })
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "5" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("task.rating_star", {
                                          star: 5
                                        })
                                      )
                                    )
                                  ])
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-md-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("autosize-textarea", {
                                  staticClass: "form-control",
                                  attrs: {
                                    row: "2",
                                    placeholder: _vm.trans(
                                      "task.rating_remarks"
                                    ),
                                    name: "remarks"
                                  },
                                  model: {
                                    value: row.remarks,
                                    callback: function($$v) {
                                      _vm.$set(row, "remarks", $$v)
                                    },
                                    expression: "row.remarks"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-info waves-effect waves-light pull-right",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(_vm._s(_vm.trans("general.save")))]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "clearfix" })
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }