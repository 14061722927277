var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade announcement-detail",
      staticStyle: { display: "none" },
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "announcementDetail",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h4",
              {
                staticClass: "modal-title",
                attrs: { id: "announcementDetail" }
              },
              [_vm._v(_vm._s(_vm.announcement.title))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-hidden": "true"
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm.announcement.user_added
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4 col-xs-6 b-r" }, [
                      _c("div", { staticClass: "user-profile pull-right" }, [
                        _c("div", { staticClass: "profile-img" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getAvatar(_vm.announcement.user_added),
                              alt: "user"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.trans("announcement.posted_by")))
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(
                            _vm.announcement.user_added.profile.first_name +
                              " " +
                              _vm.announcement.user_added.profile.last_name +
                              " (" +
                              _vm.announcement.user_added.profile.designation
                                .name +
                              " " +
                              _vm.announcement.user_added.profile.designation
                                .department.name +
                              ")"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ribbon-wrapper card",
                          staticStyle: { "margin-left": "-15px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                "ribbon",
                                "ribbon-" + _vm.announcementStatus.color
                              ]
                            },
                            [_vm._v(_vm._s(_vm.announcementStatus.label))]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.announcement.is_public
                        ? _c(
                            "div",
                            {
                              staticClass: "ribbon-wrapper card",
                              staticStyle: { "margin-left": "-15px" }
                            },
                            [
                              _c(
                                "div",
                                { class: ["ribbon", "ribbon-success"] },
                                [
                                  _vm._v(
                                    _vm._s(_vm.trans("announcement.public"))
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 col-xs-6 b-r" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.trans("announcement.start_date")))
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(_vm._f("moment")(_vm.announcement.start_date))
                        )
                      ]),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.trans("announcement.end_date")))
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(_vm._f("moment")(_vm.announcement.end_date))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 col-xs-6" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.trans("announcement.created_at")))
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("momentDateTime")(
                              _vm.announcement.created_at
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.trans("announcement.updated_at")))
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("momentDateTime")(
                              _vm.announcement.updated_at
                            )
                          )
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.announcement.user_id == _vm.getAuthUser("id") &&
              !_vm.announcement.is_public
                ? [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(_vm.trans("announcement.restricted_to")) +
                          ": " +
                          _vm._s(
                            _vm.trans(
                              _vm.announcement.restricted_to +
                                "." +
                                _vm.announcement.restricted_to
                            )
                          )
                      )
                    ]),
                    _vm._v(" "),
                    _vm.announcement.designation.length
                      ? _c(
                          "ol",
                          _vm._l(_vm.announcement.designation, function(
                            designation
                          ) {
                            return _c("li", [_vm._v(_vm._s(designation.name))])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.announcement.location.length
                      ? _c(
                          "ol",
                          _vm._l(_vm.announcement.location, function(location) {
                            return _c("li", [_vm._v(_vm._s(location.name))])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.announcement.user.length
                      ? _c(
                          "ol",
                          _vm._l(_vm.announcement.user, function(user) {
                            return _c("li", [
                              _vm._v(
                                _vm._s(
                                  user.profile.first_name +
                                    " " +
                                    user.profile.last_name +
                                    " (" +
                                    user.profile.designation.name +
                                    " " +
                                    user.profile.designation.department.name +
                                    ")"
                                )
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr")
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "m-t-20",
                domProps: { innerHTML: _vm._s(_vm.announcement.description) }
              }),
              _vm._v(" "),
              _vm.attachments.length
                ? _c("div", [
                    _c(
                      "ul",
                      {
                        staticClass: "m-t-10",
                        staticStyle: { "list-style": "none", padding: "0" }
                      },
                      _vm._l(_vm.attachments, function(attachment) {
                        return _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/announcement/" +
                                  _vm.announcement.id +
                                  "/attachment/" +
                                  attachment.uuid +
                                  "/download?token=" +
                                  _vm.authToken
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-paperclip" }),
                              _vm._v(" " + _vm._s(attachment.user_filename))
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }