var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("configuration.scheduled_job")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", {
                  attrs: { menu: "scheduled-job" }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-10 col-lg-10 col-md-10" },
                  [
                    _c("show-tip", {
                      attrs: {
                        module: "configuration",
                        tip: "tip_scheduled_job"
                      }
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("configuration.scheduled_job")))
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("Add below cron command in your server:")]),
                    _vm._v(" "),
                    _c("blockquote", [
                      _vm._v(
                        "\n                                php /path-to-artisan schedule:run >> /dev/null 2>&1\n                            "
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(0)
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-responsive" }, [
      _c(
        "table",
        { staticClass: "table table-stripped table-bordered table-hover" },
        [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Action")]),
              _vm._v(" "),
              _c("th", [_vm._v("Frequency")])
            ])
          ]),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Recurring Task")]),
              _vm._v(" "),
              _c("td", [_vm._v("Once per day at 00:00 AM")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Daily Backup")]),
              _vm._v(" "),
              _c("td", [_vm._v("Once per day at 01:00 AM")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Delete Temporary Upload")]),
              _vm._v(" "),
              _c("td", [_vm._v("Once per day at 02:00 AM")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Birthday/Anniversary wish to Staff/Customer")]),
              _vm._v(" "),
              _c("td", [_vm._v("Once per day at 09:00 AM")])
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }