var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("user.user_detail")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/user" } }, [
                _vm._v(_vm._s(_vm.trans("user.user")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("user.basic")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-12 col-lg-8 col-md-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("user-sidebar", { attrs: { menu: "basic", id: _vm.id } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-9 col-lg-9 col-md-9" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("user.basic")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submit($event)
                          },
                          keydown: function($event) {
                            return _vm.userForm.errors.clear($event.target.name)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-md-12" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.trans("user.first_name"))
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.userForm.first_name,
                                            expression: "userForm.first_name"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          value: "",
                                          name: "first_name",
                                          placeholder: _vm.trans(
                                            "user.first_name"
                                          )
                                        },
                                        domProps: {
                                          value: _vm.userForm.first_name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userForm,
                                              "first_name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.userForm,
                                          "prop-name": "first_name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.trans("user.last_name"))
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.userForm.last_name,
                                            expression: "userForm.last_name"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          value: "",
                                          name: "last_name",
                                          placeholder: _vm.trans(
                                            "user.last_name"
                                          )
                                        },
                                        domProps: {
                                          value: _vm.userForm.last_name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userForm,
                                              "last_name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.userForm,
                                          "prop-name": "last_name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(_vm._s(_vm.trans("user.email")))
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.userForm.email,
                                            expression: "userForm.email"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          value: "",
                                          name: "email",
                                          placeholder: _vm.trans("user.email"),
                                          readonly: ""
                                        },
                                        domProps: { value: _vm.userForm.email },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userForm,
                                              "email",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.userForm,
                                          "prop-name": "email"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(_vm._s(_vm.trans("user.gender")))
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.genders, function(gender) {
                                        return _c(
                                          "div",
                                          { staticClass: "radio radio-info" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.gender,
                                                  expression: "userForm.gender"
                                                }
                                              ],
                                              attrs: {
                                                type: "radio",
                                                id: gender.id,
                                                name: "gender"
                                              },
                                              domProps: {
                                                value: gender.id,
                                                checked:
                                                  _vm.userForm.gender ==
                                                  gender.id,
                                                checked: _vm._q(
                                                  _vm.userForm.gender,
                                                  gender.id
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.userForm,
                                                    "gender",
                                                    gender.id
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              { attrs: { for: gender.id } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.trans(
                                                        "list." + gender.id
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.userForm,
                                          "prop-name": "gender"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                !_vm.userHasRole(_vm.user, "admin")
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-md-6" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.trans("role.role")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-select", {
                                                attrs: {
                                                  label: "name",
                                                  "track-by": "id",
                                                  name: "role_id",
                                                  id: "role_id",
                                                  options: _vm.roles,
                                                  placeholder: _vm.trans(
                                                    "role.select_role"
                                                  ),
                                                  multiple: true,
                                                  "close-on-select": false,
                                                  "clear-on-select": false,
                                                  "hide-selected": true,
                                                  selected: _vm.selected_roles
                                                },
                                                on: {
                                                  select: _vm.onRoleSelect,
                                                  remove: _vm.onRoleRemove
                                                },
                                                model: {
                                                  value: _vm.selected_roles,
                                                  callback: function($$v) {
                                                    _vm.selected_roles = $$v
                                                  },
                                                  expression: "selected_roles"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("show-error", {
                                                attrs: {
                                                  "form-name": _vm.userForm,
                                                  "prop-name": "role_id"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-md-6" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.trans(
                                                        "designation.designation"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-select", {
                                                attrs: {
                                                  label: "name",
                                                  name: "designation_id",
                                                  id: "designation_id",
                                                  options: _vm.designations,
                                                  placeholder: _vm.trans(
                                                    "designation.select_designation"
                                                  )
                                                },
                                                on: {
                                                  select:
                                                    _vm.onDesignationSelect,
                                                  close: function($event) {
                                                    return _vm.userForm.errors.clear(
                                                      "designation_id"
                                                    )
                                                  },
                                                  remove: function($event) {
                                                    _vm.userForm.designation_id =
                                                      ""
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.selected_designation,
                                                  callback: function($$v) {
                                                    _vm.selected_designation = $$v
                                                  },
                                                  expression:
                                                    "selected_designation"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("show-error", {
                                                attrs: {
                                                  "form-name": _vm.userForm,
                                                  "prop-name": "designation_id"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-md-6" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.trans(
                                                        "location.location"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-select", {
                                                attrs: {
                                                  label: "name",
                                                  name: "location_id",
                                                  id: "location_id",
                                                  options: _vm.locations,
                                                  placeholder: _vm.trans(
                                                    "location.select_location"
                                                  )
                                                },
                                                on: {
                                                  select: _vm.onLocationSelect,
                                                  close: function($event) {
                                                    return _vm.userForm.errors.clear(
                                                      "location_id"
                                                    )
                                                  },
                                                  remove: function($event) {
                                                    _vm.userForm.location_id =
                                                      ""
                                                  }
                                                },
                                                model: {
                                                  value: _vm.selected_location,
                                                  callback: function($$v) {
                                                    _vm.selected_location = $$v
                                                  },
                                                  expression:
                                                    "selected_location"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("show-error", {
                                                attrs: {
                                                  "form-name": _vm.userForm,
                                                  "prop-name": "location_id"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.trans("user.date_of_birth")
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("datepicker", {
                                        attrs: {
                                          bootstrapStyling: true,
                                          name: "date_of_birth"
                                        },
                                        on: {
                                          selected: function($event) {
                                            return _vm.userForm.errors.clear(
                                              "date_of_birth"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.userForm.date_of_birth,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.userForm,
                                              "date_of_birth",
                                              $$v
                                            )
                                          },
                                          expression: "userForm.date_of_birth"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.userForm,
                                          "prop-name": "date_of_birth"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.trans(
                                              "user.date_of_anniversary"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("datepicker", {
                                        attrs: {
                                          bootstrapStyling: true,
                                          name: "date_of_anniversary"
                                        },
                                        on: {
                                          selected: function($event) {
                                            return _vm.userForm.errors.clear(
                                              "date_of_anniversary"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.userForm.date_of_anniversary,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.userForm,
                                              "date_of_anniversary",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "userForm.date_of_anniversary"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.userForm,
                                          "prop-name": "date_of_anniversary"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Login Time")
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.userForm.login_time,
                                            expression: "userForm.login_time"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          value: "",
                                          name: "login_time",
                                          placeholder: _vm.trans(
                                            "user.login_time"
                                          ),
                                          readonly: ""
                                        },
                                        domProps: {
                                          value: _vm.userForm.login_time
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userForm,
                                              "login_time",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.userForm,
                                          "prop-name": "login_time"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Late Mark")
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.userForm.late_mark,
                                            expression: "userForm.late_mark"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          value: "",
                                          name: "late_mark",
                                          placeholder: _vm.trans(
                                            "user.late_mark"
                                          ),
                                          readonly: ""
                                        },
                                        domProps: {
                                          value: _vm.userForm.late_mark
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userForm,
                                              "late_mark",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.userForm,
                                          "prop-name": "late_mark"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              2
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-info pull-right save-btn",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(_vm._s(_vm.trans("general.save")))]
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("user-summary", { attrs: { user: _vm.user } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }