var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-2 col-lg-2 col-md-2" }, [
    _c(
      "div",
      { staticClass: "list-group" },
      [
        _c(
          "router-link",
          {
            class: [_vm.menu === "basic" ? "active" : "", "list-group-item"],
            attrs: { to: "/configuration/basic" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-home" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("general.basic")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [_vm.menu === "logo" ? "active" : "", "list-group-item"],
            attrs: { to: "/configuration/logo" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-image" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("general.logo")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [_vm.menu === "system" ? "active" : "", "list-group-item"],
            attrs: { to: "/configuration/system" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-cogs" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("general.system")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [_vm.menu === "mail" ? "active" : "", "list-group-item"],
            attrs: { to: "/configuration/mail" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-envelope" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("mail.mail")))
            ])
          ]
        ),
        _vm._v(" "),
        _vm.getConfig("multilingual")
          ? _c(
              "router-link",
              {
                class: [
                  _vm.menu === "locale" ? "active" : "",
                  "list-group-item"
                ],
                attrs: { to: "/configuration/locale" }
              },
              [
                _c("span", { staticClass: "hidden-sm-up" }, [
                  _c("i", { staticClass: "fas fa-globe" })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "hidden-xs-down" }, [
                  _vm._v(_vm._s(_vm.trans("locale.locale")))
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [_vm.menu === "role" ? "active" : "", "list-group-item"],
            attrs: { to: "/configuration/role" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-users" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("role.role")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [
              _vm.menu === "permission" ? "active" : "",
              "list-group-item"
            ],
            attrs: { to: "/configuration/permission" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-users" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("permission.permission")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [_vm.menu === "menu" ? "active" : "", "list-group-item"],
            attrs: { to: "/configuration/menu" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-list" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("general.menu")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [
              _vm.menu === "authentication" ? "active" : "",
              "list-group-item"
            ],
            attrs: { to: "/configuration/authentication" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-sign-in" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("auth.authentication")))
            ])
          ]
        ),
        _vm._v(" "),
        _vm.getConfig("ip_filter")
          ? _c(
              "router-link",
              {
                class: [
                  _vm.menu === "ip-filter" ? "active" : "",
                  "list-group-item"
                ],
                attrs: { to: "/configuration/ip-filter" }
              },
              [
                _c("span", { staticClass: "hidden-sm-up" }, [
                  _c("i", { staticClass: "fas fa-ellipsis-v" })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "hidden-xs-down" }, [
                  _vm._v(_vm._s(_vm.trans("ip_filter.ip_filter")))
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [_vm.menu === "task" ? "active" : "", "list-group-item"],
            attrs: { to: "/configuration/task" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-tasks" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("task.task")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [_vm.menu === "question" ? "active" : "", "list-group-item"],
            attrs: { to: "/configuration/question" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-question" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("task.question")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            class: [
              _vm.menu === "scheduled-job" ? "active" : "",
              "list-group-item"
            ],
            attrs: { to: "/configuration/scheduled-job" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "far fa-clock" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(_vm._s(_vm.trans("general.scheduled_job")))
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }