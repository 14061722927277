var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed($event)
        },
        keydown: function($event) {
          return _vm.locationForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("location.name")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.locationForm.name,
                expression: "locationForm.name"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              name: "name",
              placeholder: _vm.trans("location.name")
            },
            domProps: { value: _vm.locationForm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.locationForm, "name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.locationForm, "prop-name": "name" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "label",
            { attrs: { for: "" } },
            [
              _vm._v(_vm._s(_vm.trans("location.top_location")) + " "),
              _c("show-tip", {
                attrs: {
                  module: "location",
                  tip: "tip_top_location",
                  type: "field"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              label: "name",
              name: "top_location_id",
              id: "top_location_id",
              options: _vm.topLocations,
              placeholder: _vm.trans("location.select_top_location")
            },
            on: {
              select: _vm.onTopLocationSelect,
              close: function($event) {
                return _vm.locationForm.errors.clear("top_location_id")
              },
              remove: function($event) {
                _vm.locationForm.top_location_id = ""
              }
            },
            model: {
              value: _vm.selected_top_location,
              callback: function($$v) {
                _vm.selected_top_location = $$v
              },
              expression: "selected_top_location"
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: {
              "form-name": _vm.locationForm,
              "prop-name": "top_location_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("switches", {
            staticClass: "m-l-20",
            attrs: { theme: "bootstrap", color: "success" },
            model: {
              value: _vm.locationForm.is_default,
              callback: function($$v) {
                _vm.$set(_vm.locationForm, "is_default", $$v)
              },
              expression: "locationForm.is_default"
            }
          }),
          _vm._v(" " + _vm._s(_vm.trans("location.default")) + " "),
          _c("show-tip", {
            attrs: {
              module: "location",
              tip: "tip_default_location",
              type: "field"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("location.description")))
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.locationForm.description,
                expression: "locationForm.description"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              rows: "2",
              name: "description",
              placeholder: _vm.trans("location.description")
            },
            domProps: { value: _vm.locationForm.description },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.locationForm, "description", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.locationForm, "prop-name": "description" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass: "btn btn-danger waves-effect waves-light",
          attrs: { to: "/location" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }