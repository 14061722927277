var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPanel
    ? _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h4", { staticClass: "card-title" }, [
            _vm._v(_vm._s(_vm.trans("task.recurring_task")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-4 col-md-4 col-sm-4" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.update($event)
                    },
                    keydown: function($event) {
                      return _vm.recurrenceForm.errors.clear($event.target.name)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("switches", {
                        staticClass: "m-l-20",
                        attrs: { theme: "bootstrap", color: "success" },
                        model: {
                          value: _vm.recurrenceForm.is_recurring,
                          callback: function($$v) {
                            _vm.$set(_vm.recurrenceForm, "is_recurring", $$v)
                          },
                          expression: "recurrenceForm.is_recurring"
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.trans("task.recurring")) +
                          "\n                    "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.recurrenceForm.is_recurring
                    ? _c("div", [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.trans("task.next_recurring_date"))
                            )
                          ]),
                          _vm._v(" "),
                          _vm.next_recurring_date
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(_vm.next_recurring_date)
                                  )
                                )
                              ])
                            : _c("span", { staticClass: "has-error" }, [
                                _c("strong", [_vm._v("-")])
                              ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("task.recurrence_start_date"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("datepicker", {
                              attrs: {
                                bootstrapStyling: true,
                                placeholder: _vm.trans(
                                  "task.recurrence_start_date"
                                )
                              },
                              on: {
                                selected: function($event) {
                                  return _vm.recurrenceForm.errors.clear(
                                    "recurrence_start_date"
                                  )
                                }
                              },
                              model: {
                                value: _vm.recurrenceForm.recurrence_start_date,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.recurrenceForm,
                                    "recurrence_start_date",
                                    $$v
                                  )
                                },
                                expression:
                                  "recurrenceForm.recurrence_start_date"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.recurrenceForm,
                                "prop-name": "recurrence_start_date"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("task.recurrence_end_date"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("datepicker", {
                              attrs: {
                                bootstrapStyling: true,
                                placeholder: _vm.trans(
                                  "task.recurrence_end_date"
                                )
                              },
                              on: {
                                selected: function($event) {
                                  return _vm.recurrenceForm.errors.clear(
                                    "recurrence_end_date"
                                  )
                                }
                              },
                              model: {
                                value: _vm.recurrenceForm.recurrence_end_date,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.recurrenceForm,
                                    "recurrence_end_date",
                                    $$v
                                  )
                                },
                                expression: "recurrenceForm.recurrence_end_date"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.recurrenceForm,
                                "prop-name": "recurrence_end_date"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("task.recurring_frequency"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              attrs: {
                                label: "name",
                                name: "recurring_frequency",
                                id: "recurring_frequency",
                                options: _vm.recurring_frequencies,
                                placeholder: _vm.trans("task.select_frequency")
                              },
                              on: {
                                select: _vm.onRecurringFrequencySelect,
                                close: function($event) {
                                  return _vm.recurrenceForm.errors.clear(
                                    "recurring_frequency"
                                  )
                                },
                                remove: function($event) {
                                  _vm.recurrenceForm.recurring_frequency = ""
                                }
                              },
                              model: {
                                value: _vm.selected_recurring_frequency,
                                callback: function($$v) {
                                  _vm.selected_recurring_frequency = $$v
                                },
                                expression: "selected_recurring_frequency"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.recurrenceForm,
                                "prop-name": "recurring_frequency"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info waves-effect waves-light",
                      attrs: { type: "submit" }
                    },
                    [_vm._v(_vm._s(_vm.trans("general.save")))]
                  ),
                  _vm._v(" "),
                  _vm.showPanel
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: { click: _vm.toggle }
                        },
                        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-8 col-md-8 col-sm-8" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("task.recurring_task_list")))
                ]),
                _vm._v(" "),
                _vm.recurring_tasks
                  ? _c("h6", { staticClass: "card-subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.trans("general.total_result_found", {
                            count: _vm.recurring_tasks.total
                          })
                        )
                      )
                    ])
                  : _c("h6", { staticClass: "card-subtitle" }, [
                      _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _vm.recurring_tasks.total
                    ? _c("table", { staticClass: "table table-bordered" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v(_vm._s(_vm.trans("task.title")))]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("task.start_date")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("task.due_date")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("task.status")))
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "table-option-sm" }, [
                              _vm._v(_vm._s(_vm.trans("general.action")))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.recurring_tasks.data, function(
                            recurring_task
                          ) {
                            return _c("tr", [
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(recurring_task.title)
                                }
                              }),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(recurring_task.start_date)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(recurring_task.due_date)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.getTaskStatus(recurring_task),
                                  function(status) {
                                    return _c(
                                      "span",
                                      {
                                        class: [
                                          "label",
                                          "label-" + status.color
                                        ],
                                        staticStyle: { "margin-right": "5px" }
                                      },
                                      [_vm._v(_vm._s(status.label))]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "table-option-sm" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "btn btn-info btn-sm",
                                      attrs: {
                                        to: "/task/" + recurring_task.uuid
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-arrow-circle-right"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("pagination-record", {
                  attrs: {
                    "page-length": _vm.filterRecurringTaskForm.page_length,
                    records: _vm.recurring_tasks
                  },
                  on: {
                    "update:pageLength": function($event) {
                      return _vm.$set(
                        _vm.filterRecurringTaskForm,
                        "page_length",
                        $event
                      )
                    },
                    "update:page-length": function($event) {
                      return _vm.$set(
                        _vm.filterRecurringTaskForm,
                        "page_length",
                        $event
                      )
                    },
                    updateRecords: _vm.getRecurringTasks
                  },
                  nativeOn: {
                    change: function($event) {
                      return _vm.getRecurringTasks($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }