var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c("div", { staticClass: "login-register" }, [
      _c("div", { staticClass: "login-box card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h3", { staticClass: "box-title m-b-20" }, [
            _vm._v(_vm._s(_vm.trans("auth.tnc")))
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("Here goes terms and conditions!")]),
          _vm._v(" "),
          _c("div", { staticClass: "text-center m-t-20" }, [
            _c(
              "div",
              { staticClass: "col-xs-12" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light",
                    attrs: { to: "/" }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.back")))]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }