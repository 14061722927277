var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.tasks
      ? _c("h6", { staticClass: "card-subtitle" }, [
          _vm._v(
            _vm._s(
              _vm.trans("general.total_result_found", {
                count: _vm.tasks.total
              })
            )
          )
        ])
      : _c("h6", { staticClass: "card-subtitle" }, [
          _vm._v(_vm._s(_vm.trans("general.no_result_found")))
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-responsive" }, [
      _vm.tasks.total
        ? _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.trans("task.number")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.trans("task.title")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.trans("task.category")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.trans("task.priority")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.trans("task.start_date")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.trans("task.due_date")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.trans("task.progress")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.trans("task.status")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.trans("task.owner")))]),
                _vm._v(" "),
                _c("th", { staticClass: "table-option" }, [
                  _vm._v(_vm._s(_vm.trans("general.action")))
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.tasks.data, function(task) {
                return _c("tr", [
                  _c("td", {
                    domProps: { textContent: _vm._s(_vm.getTaskNumber(task)) }
                  }),
                  _vm._v(" "),
                  _c("td", { domProps: { textContent: _vm._s(task.title) } }),
                  _vm._v(" "),
                  _c("td", {
                    domProps: { textContent: _vm._s(task.task_category.name) }
                  }),
                  _vm._v(" "),
                  _c("td", {
                    domProps: { textContent: _vm._s(task.task_priority.name) }
                  }),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm._f("moment")(task.start_date)))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm._f("moment")(task.due_date)))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticClass: "progress",
                        staticStyle: { height: "10px" }
                      },
                      [
                        _c("div", {
                          class: _vm.getTaskProgressColor(task),
                          style: _vm.getTaskProgressWidth(task),
                          attrs: {
                            role: "progressbar",
                            "aria-valuenow": _vm.getTaskProgress(task),
                            "aria-valuemin": "0",
                            "aria-valuemax": "100"
                          }
                        })
                      ]
                    ),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.getTaskProgress(task)) +
                        " %\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    _vm._l(_vm.getTaskStatus(task), function(status) {
                      return _c(
                        "span",
                        {
                          class: ["label", "label-" + status.color],
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(status.label))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        task.user_added.profile.first_name +
                          " " +
                          task.user_added.profile.last_name
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "table-option" }, [
                    _c(
                      "div",
                      { staticClass: "btn-group" },
                      [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.trans("task.view_task"),
                                expression: "trans('task.view_task')"
                              }
                            ],
                            staticClass: "btn btn-success btn-sm",
                            attrs: { to: "/task/" + task.uuid }
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-arrow-circle-right"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              }),
              0
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }