var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade task-config",
      staticStyle: { display: "none" },
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "taskConfig",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h4",
              { staticClass: "modal-title", attrs: { id: "taskConfig" } },
              [
                _vm._v(
                  _vm._s(_vm.trans("task.configuration")) + "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-hidden": "true"
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  },
                  keydown: function($event) {
                    return _vm.taskConfigForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("task.progress_type")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio radio-info" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskConfigForm.progress_type,
                            expression: "taskConfigForm.progress_type"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "manual",
                          id: "progress_type_manual",
                          name: "progress_type"
                        },
                        domProps: {
                          checked:
                            _vm.taskConfigForm.progress_type === "manual",
                          checked: _vm._q(
                            _vm.taskConfigForm.progress_type,
                            "manual"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.taskConfigForm,
                              "progress_type",
                              "manual"
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "progress_type_manual" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.trans("task.manual_progress")) + " "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio radio-info" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskConfigForm.progress_type,
                            expression: "taskConfigForm.progress_type"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "sub_task",
                          id: "progress_type_sub_task",
                          name: "progress_type"
                        },
                        domProps: {
                          checked:
                            _vm.taskConfigForm.progress_type === "sub_task",
                          checked: _vm._q(
                            _vm.taskConfigForm.progress_type,
                            "sub_task"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.taskConfigForm,
                              "progress_type",
                              "sub_task"
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "progress_type_sub_task" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.trans("task.sub_task_progress")) +
                              " "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio radio-info" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskConfigForm.progress_type,
                            expression: "taskConfigForm.progress_type"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "question",
                          id: "progress_type_question",
                          name: "progress_type"
                        },
                        domProps: {
                          checked:
                            _vm.taskConfigForm.progress_type === "question",
                          checked: _vm._q(
                            _vm.taskConfigForm.progress_type,
                            "question"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.taskConfigForm,
                              "progress_type",
                              "question"
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "progress_type_question" } },
                        [_vm._v(" " + _vm._s(_vm.trans("task.question")) + " ")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.taskConfigForm,
                        "prop-name": "progress_type"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("task.rating_type")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio radio-info" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskConfigForm.rating_type,
                            expression: "taskConfigForm.rating_type"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "task_based",
                          id: "rating_type_task_based",
                          name: "rating_type"
                        },
                        domProps: {
                          checked:
                            _vm.taskConfigForm.rating_type === "task_based",
                          checked: _vm._q(
                            _vm.taskConfigForm.rating_type,
                            "task_based"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.taskConfigForm,
                              "rating_type",
                              "task_based"
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "rating_type_task_based" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.trans("task.task_based_rating")) +
                              " "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio radio-info" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskConfigForm.rating_type,
                            expression: "taskConfigForm.rating_type"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "sub_task_based",
                          id: "rating_type_sub_task_based",
                          name: "rating_type"
                        },
                        domProps: {
                          checked:
                            _vm.taskConfigForm.rating_type === "sub_task_based",
                          checked: _vm._q(
                            _vm.taskConfigForm.rating_type,
                            "sub_task_based"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.taskConfigForm,
                              "rating_type",
                              "sub_task_based"
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "rating_type_sub_task_based" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.trans("task.sub_task_based_rating")) +
                              " "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.taskConfigForm,
                        "prop-name": "rating_type"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info waves-effect waves-light",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.save")))]
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }