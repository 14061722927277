var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("general.report")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("Log report")
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-md-6 col-8 align-right",
          staticStyle: { float: "right" }
        },
        [
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-info btn-md pull-right",
                staticStyle: { "margin-left": "auto", display: "block" },
                attrs: { type: "button" },
                on: { click: _vm.excel }
              },
              [_vm._v("\n                    Excel\n                ")]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.general_filter.email,
                          expression: "general_filter.email"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.general_filter.email },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getReport()
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.general_filter,
                            "email",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.general_filter.name,
                          expression: "general_filter.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.general_filter.name },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getReport()
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.general_filter,
                            "name",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.general_filter.date,
                          expression: "general_filter.date"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "date" },
                      domProps: { value: _vm.general_filter.date },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getReport()
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.general_filter,
                            "date",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.reports, function(report) {
                    return _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(report.user && report.user.email))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            report.user && report.user.profile.first_name
                          ) +
                            " " +
                            _vm._s(report.user && report.user.profile.last_name)
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            report.user && report.user.profile.location.name
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(report.created_date))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(report.temp.in_out.late_mark))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(report.w_hours))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(report.difference))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(report.temp.checked_in))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(report.temp.checked_out))])
                    ])
                  }),
                  0
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("User Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Branch")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date & Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Late Mark")]),
        _vm._v(" "),
        _c("th", [_vm._v("Working Hours")]),
        _vm._v(" "),
        _c("th", [_vm._v("Break")]),
        _vm._v(" "),
        _c("th", [_vm._v("First Checkin")]),
        _vm._v(" "),
        _c("th", [_vm._v("Last Checkout")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }