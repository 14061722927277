var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("locale.locale")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", { attrs: { menu: "locale" } }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-10 col-lg-10 col-md-10" },
                  [
                    _c("show-tip", {
                      attrs: { module: "locale", tip: "tip_locale" }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-4 col-md-4" },
                        [
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.trans("locale.add_new_locale")))
                          ]),
                          _vm._v(" "),
                          _c("locale-form", {
                            on: { completed: _vm.getLocales }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" }),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "card-title m-t-20" },
                            [
                              _vm._v(
                                _vm._s(_vm.trans("locale.add_new_word")) + " "
                              ),
                              _c("show-tip", {
                                attrs: {
                                  module: "locale",
                                  tip: "tip_add_word",
                                  type: "field"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.addWord($event)
                                },
                                keydown: function($event) {
                                  return _vm.addWordForm.errors.clear(
                                    $event.target.name
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(_vm._s(_vm.trans("locale.word")))
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addWordForm.word,
                                        expression: "addWordForm.word"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      value: "",
                                      name: "word",
                                      placeholder: _vm.trans("locale.word")
                                    },
                                    domProps: { value: _vm.addWordForm.word },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addWordForm,
                                          "word",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.addWordForm,
                                      "prop-name": "word"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(_vm._s(_vm.trans("locale.module")))
                                  ]),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      name: "module",
                                      id: "module",
                                      options: _vm.modules,
                                      placeholder: _vm.trans(
                                        "locale.select_module"
                                      )
                                    },
                                    on: {
                                      select: function($event) {
                                        return _vm.addWordForm.errors.clear(
                                          "module"
                                        )
                                      },
                                      remove: function($event) {
                                        _vm.addWordForm.module = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.addWordForm.module,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addWordForm, "module", $$v)
                                      },
                                      expression: "addWordForm.module"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.addWordForm,
                                      "prop-name": "module"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("locale.translation"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addWordForm.translation,
                                        expression: "addWordForm.translation"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      value: "",
                                      name: "translation",
                                      placeholder: _vm.trans(
                                        "locale.translation"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addWordForm.translation
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addWordForm,
                                          "translation",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.addWordForm,
                                      "prop-name": "translation"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-info waves-effect waves-light pull-right",
                                  attrs: { type: "submit" }
                                },
                                [
                                  _c("span", { attrs: { v: "" } }, [
                                    _vm._v(_vm._s(_vm.trans("general.add")))
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-8 col-md-8" },
                        [
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.trans("locale.locale_list")))
                          ]),
                          _vm._v(" "),
                          _vm.locales
                            ? _c("h6", { staticClass: "card-subtitle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("general.total_result_found", {
                                      count: _vm.locales.total
                                    })
                                  )
                                )
                              ])
                            : _c("h6", { staticClass: "card-subtitle" }, [
                                _vm._v(
                                  _vm._s(_vm.trans("general.no_result_found"))
                                )
                              ]),
                          _vm._v(" "),
                          _vm.locales.total
                            ? _c("div", { staticClass: "table-responsive" }, [
                                _c("table", { staticClass: "table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.trans("general.name"))
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.trans("locale.locale"))
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        { staticClass: "table-option" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.trans("general.action"))
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.locales.data, function(locale) {
                                      return _c("tr", [
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(locale.name)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(locale.locale)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "table-option" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "btn-group" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: _vm.trans(
                                                          "locale.edit_locale"
                                                        ),
                                                        expression:
                                                          "trans('locale.edit_locale')"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "btn btn-info btn-sm",
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.editLocale(
                                                          locale
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fas fa-edit"
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "router-link",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: _vm.trans(
                                                          "locale.translation"
                                                        ),
                                                        expression:
                                                          "trans('locale.translation')"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "btn btn-success btn-sm",
                                                    attrs: {
                                                      to:
                                                        "/configuration/locale/" +
                                                        locale.locale +
                                                        "/auth"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-arrow-circle-right"
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                locale.locale !== "en"
                                                  ? _c(
                                                      "button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "confirm",
                                                            rawName:
                                                              "v-confirm",
                                                            value: {
                                                              ok: _vm.confirmDelete(
                                                                locale
                                                              )
                                                            },
                                                            expression:
                                                              "{ok: confirmDelete(locale)}"
                                                          },
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip",
                                                            value: _vm.trans(
                                                              "locale.delete_locale"
                                                            ),
                                                            expression:
                                                              "trans('locale.delete_locale')"
                                                          }
                                                        ],
                                                        key: locale.id,
                                                        staticClass:
                                                          "btn btn-danger btn-sm"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-trash"
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.locales.total
                            ? _c("module-info", {
                                attrs: {
                                  module: "locale",
                                  title: "module_info_title",
                                  description: "module_info_description",
                                  icon: "globe"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("pagination-record", {
                            attrs: {
                              "page-length": _vm.filterLocaleForm.page_length,
                              records: _vm.locales
                            },
                            on: {
                              "update:pageLength": function($event) {
                                return _vm.$set(
                                  _vm.filterLocaleForm,
                                  "page_length",
                                  $event
                                )
                              },
                              "update:page-length": function($event) {
                                return _vm.$set(
                                  _vm.filterLocaleForm,
                                  "page_length",
                                  $event
                                )
                              },
                              updateRecords: _vm.getLocales
                            },
                            nativeOn: {
                              change: function($event) {
                                return _vm.getLocales($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }