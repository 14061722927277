var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("task.task")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("task.task")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.hasPermission("create-task")
            ? _c("transition", { attrs: { name: "fade" } }, [
                _vm.showCreatePanel
                  ? _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _vm.showCreatePanel
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-info btn-sm pull-right",
                                  on: {
                                    click: function($event) {
                                      _vm.showCreatePanel = !_vm.showCreatePanel
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.trans("general.hide")))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.trans("task.add_new_task")))
                          ]),
                          _vm._v(" "),
                          _c("task-form", { on: { completed: _vm.getTasks } })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.showFilterPanel
              ? _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _vm.showFilterPanel
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-info btn-sm pull-right",
                            on: {
                              click: function($event) {
                                _vm.showFilterPanel = !_vm.showFilterPanel
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.trans("general.hide")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("general.filter")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("task.number")))
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filterTaskForm.number,
                                expression: "filterTaskForm.number"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "title" },
                            domProps: { value: _vm.filterTaskForm.number },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filterTaskForm,
                                  "number",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("task.title")))
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filterTaskForm.title,
                                expression: "filterTaskForm.title"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "title" },
                            domProps: { value: _vm.filterTaskForm.title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filterTaskForm,
                                  "title",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("task.task_category")))
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              attrs: {
                                label: "name",
                                "track-by": "id",
                                name: "task_category_id",
                                id: "task_category_id",
                                options: _vm.task_categories,
                                placeholder: _vm.trans(
                                  "task.select_task_category"
                                ),
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "hide-selected": true
                              },
                              on: {
                                select: _vm.onTaskCategorySelect,
                                remove: _vm.onTaskCategoryRemove
                              },
                              model: {
                                value: _vm.selected_task_category,
                                callback: function($$v) {
                                  _vm.selected_task_category = $$v
                                },
                                expression: "selected_task_category"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("task.task_priority")))
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              attrs: {
                                label: "name",
                                "track-by": "id",
                                name: "task_priority_id",
                                id: "task_priority_id",
                                options: _vm.task_priorities,
                                placeholder: _vm.trans(
                                  "task.select_task_priority"
                                ),
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "hide-selected": true
                              },
                              on: {
                                select: _vm.onTaskPrioritySelect,
                                remove: _vm.onTaskPriorityRemove
                              },
                              model: {
                                value: _vm.selected_task_priority,
                                callback: function($$v) {
                                  _vm.selected_task_priority = $$v
                                },
                                expression: "selected_task_priority"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("task.assigned_user")))
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              attrs: {
                                label: "name",
                                "track-by": "id",
                                name: "user_id",
                                id: "user_id",
                                options: _vm.users,
                                placeholder: _vm.trans("user.select_user"),
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "hide-selected": true
                              },
                              on: {
                                select: _vm.onUserSelect,
                                remove: _vm.onUserRemove
                              },
                              model: {
                                value: _vm.selected_user,
                                callback: function($$v) {
                                  _vm.selected_user = $$v
                                },
                                expression: "selected_user"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("task.type")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterTaskForm.type,
                                      expression: "filterTaskForm.type"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    name: "type",
                                    placeholder: _vm.trans("general.select_one")
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filterTaskForm,
                                        "type",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.select_one"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "owned" } }, [
                                    _vm._v(_vm._s(_vm.trans("task.owned")))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "assigned" } },
                                    [_vm._v(_vm._s(_vm.trans("task.assigned")))]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("task.starred")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterTaskForm.starred,
                                      expression: "filterTaskForm.starred"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    name: "starred",
                                    placeholder: _vm.trans("general.select_one")
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filterTaskForm,
                                        "starred",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.select_one"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "starred" } },
                                    [_vm._v(_vm._s(_vm.trans("task.starred")))]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("task.archive")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterTaskForm.is_archived,
                                      expression: "filterTaskForm.is_archived"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    name: "is_archived",
                                    placeholder: _vm.trans("general.select_one")
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filterTaskForm,
                                        "is_archived",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.select_one"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "archived" } },
                                    [_vm._v(_vm._s(_vm.trans("task.archived")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "unarchived" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.trans("task.unarchived"))
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("date-range-picker", {
                              attrs: {
                                "start-date":
                                  _vm.filterTaskForm.start_date_start,
                                "end-date": _vm.filterTaskForm.start_date_end,
                                label: _vm.trans("task.start_date")
                              },
                              on: {
                                "update:startDate": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "start_date_start",
                                    $event
                                  )
                                },
                                "update:start-date": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "start_date_start",
                                    $event
                                  )
                                },
                                "update:endDate": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "start_date_end",
                                    $event
                                  )
                                },
                                "update:end-date": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "start_date_end",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("date-range-picker", {
                              attrs: {
                                "start-date": _vm.filterTaskForm.due_date_start,
                                "end-date": _vm.filterTaskForm.due_date_end,
                                label: _vm.trans("task.due_date")
                              },
                              on: {
                                "update:startDate": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "due_date_start",
                                    $event
                                  )
                                },
                                "update:start-date": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "due_date_start",
                                    $event
                                  )
                                },
                                "update:endDate": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "due_date_end",
                                    $event
                                  )
                                },
                                "update:end-date": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "due_date_end",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("date-range-picker", {
                              attrs: {
                                "start-date":
                                  _vm.filterTaskForm.completed_at_start,
                                "end-date": _vm.filterTaskForm.completed_at_end,
                                label: _vm.trans("task.completed_at")
                              },
                              on: {
                                "update:startDate": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "completed_at_start",
                                    $event
                                  )
                                },
                                "update:start-date": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "completed_at_start",
                                    $event
                                  )
                                },
                                "update:endDate": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "completed_at_end",
                                    $event
                                  )
                                },
                                "update:end-date": function($event) {
                                  return _vm.$set(
                                    _vm.filterTaskForm,
                                    "completed_at_end",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("task.status")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterTaskForm.status,
                                  expression: "filterTaskForm.status"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                name: "status",
                                placeholder: _vm.trans("general.select_one")
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filterTaskForm,
                                    "status",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.trans("general.select_one")))
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "unassigned" } }, [
                                _vm._v(_vm._s(_vm.trans("task.unassigned")))
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "requested" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("task.task_sign_off_status", {
                                      status: _vm.trans("task.requested")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "rejected" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("task.task_sign_off_status", {
                                      status: _vm.trans("task.rejected")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "cancelled" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("task.task_sign_off_status", {
                                      status: _vm.trans("task.cancelled")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "pending" } }, [
                                _vm._v(_vm._s(_vm.trans("task.pending")))
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "approved" } }, [
                                _vm._v(_vm._s(_vm.trans("task.completed")))
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "overdue" } }, [
                                _vm._v(_vm._s(_vm.trans("task.overdue")))
                              ])
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("task.recurring")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterTaskForm.is_recurring,
                                  expression: "filterTaskForm.is_recurring"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                name: "is_recurring",
                                placeholder: _vm.trans("general.select_one")
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filterTaskForm,
                                    "is_recurring",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.trans("general.select_one")))
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v(_vm._s(_vm.trans("task.recurring")))
                              ])
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("general.sort_by")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterTaskForm.sort_by,
                                  expression: "filterTaskForm.sort_by"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "sort_by" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filterTaskForm,
                                    "sort_by",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "title" } }, [
                                _vm._v(_vm._s(_vm.trans("task.title")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "task_category_id" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.trans("task.task_category"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "task_priority_id" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.trans("task.task_priority"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "start_date" } }, [
                                _vm._v(_vm._s(_vm.trans("task.start_date")))
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "due_date" } }, [
                                _vm._v(_vm._s(_vm.trans("task.due_date")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "completed_at" } },
                                [_vm._v(_vm._s(_vm.trans("task.completed_at")))]
                              ),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "created_at" } }, [
                                _vm._v(_vm._s(_vm.trans("task.created_at")))
                              ])
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("general.order")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterTaskForm.order,
                                  expression: "filterTaskForm.order"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "order" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filterTaskForm,
                                    "order",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "asc" } }, [
                                _vm._v(_vm._s(_vm.trans("general.ascending")))
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "desc" } }, [
                                _vm._v(_vm._s(_vm.trans("general.descending")))
                              ])
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.hasPermission("list-task")
            ? _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    !_vm.showFilterPanel
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-info btn-sm pull-right",
                            on: {
                              click: function($event) {
                                _vm.showFilterPanel = !_vm.showFilterPanel
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-filter" }),
                            _vm._v(" " + _vm._s(_vm.trans("general.filter")))
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.tasks.total && !_vm.showCreatePanel
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-info btn-sm pull-right m-r-5",
                            on: {
                              click: function($event) {
                                _vm.showCreatePanel = !_vm.showCreatePanel
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v(" " + _vm._s(_vm.trans("general.add_new")))
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("task.task_list")) + " "),
                      _vm.filterTaskForm.user_id.length == 1
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.trans("general.of") +
                                  " " +
                                  _vm.getRatingUser
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.tasks
                      ? _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(
                            _vm._s(
                              _vm.trans("general.total_result_found", {
                                count: _vm.tasks.total
                              })
                            )
                          )
                        ])
                      : _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                        ]),
                    _vm._v(" "),
                    _vm.tasks.total
                      ? _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.number")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.title")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.category")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.priority")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.start_date")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.due_date")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.progress")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.status")))
                                ]),
                                _vm._v(" "),
                                _vm.filterTaskForm.user_id.length == 1
                                  ? _c("th", [
                                      _vm._v(_vm._s(_vm.trans("task.rating")))
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.owner")))
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "table-option" }, [
                                  _vm._v(_vm._s(_vm.trans("general.action")))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.tasks.data, function(task) {
                                return _c("tr", [
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.getTaskNumber(task)
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(task.title)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        task.task_category.name
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        task.task_priority.name
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm._f("moment")(task.start_date))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm._f("moment")(task.due_date))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "progress",
                                        staticStyle: { height: "10px" }
                                      },
                                      [
                                        _c("div", {
                                          class: _vm.getTaskProgressColor(task),
                                          style: _vm.getTaskProgressWidth(task),
                                          attrs: {
                                            role: "progressbar",
                                            "aria-valuenow": _vm.getTaskProgress(
                                              task
                                            ),
                                            "aria-valuemin": "0",
                                            "aria-valuemax": "100"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.getTaskProgress(task)) +
                                        " %\n                                    "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(_vm.getTaskStatus(task), function(
                                      status
                                    ) {
                                      return _c(
                                        "span",
                                        {
                                          class: [
                                            "label",
                                            "label-" + status.color
                                          ],
                                          staticStyle: { "margin-right": "5px" }
                                        },
                                        [_vm._v(_vm._s(status.label))]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _vm.filterTaskForm.user_id.length == 1
                                    ? _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(_vm.getRating(task))
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        task.user_added.profile.first_name +
                                          " " +
                                          task.user_added.profile.last_name
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "table-option" }, [
                                    _c(
                                      "div",
                                      { staticClass: "btn-group" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "task.view_task"
                                                ),
                                                expression:
                                                  "trans('task.view_task')"
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-success btn-sm",
                                            attrs: { to: "/task/" + task.uuid }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-arrow-circle-right"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        task.sign_off_status === "approved" &&
                                        task.user_id == _vm.getAuthUser("id")
                                          ? [
                                              !task.is_archived
                                                ? _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.trans(
                                                            "task.move_to_archive"
                                                          ),
                                                          expression:
                                                            "trans('task.move_to_archive')"
                                                        },
                                                        {
                                                          name: "confirm",
                                                          rawName: "v-confirm",
                                                          value: {
                                                            ok: _vm.confirmToggleArchive(
                                                              task
                                                            )
                                                          },
                                                          expression:
                                                            "{ok: confirmToggleArchive(task)}"
                                                        }
                                                      ],
                                                      key: "archive_" + task.id,
                                                      staticClass:
                                                        "btn btn-warning btn-sm"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-archive"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              task.is_archived
                                                ? _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.trans(
                                                            "task.remove_from_archive"
                                                          ),
                                                          expression:
                                                            "trans('task.remove_from_archive')"
                                                        },
                                                        {
                                                          name: "confirm",
                                                          rawName: "v-confirm",
                                                          value: {
                                                            ok: _vm.confirmToggleArchive(
                                                              task
                                                            )
                                                          },
                                                          expression:
                                                            "{ok: confirmToggleArchive(task)}"
                                                        }
                                                      ],
                                                      key:
                                                        "unarchive_" + task.id,
                                                      staticClass:
                                                        "btn btn-warning btn-sm"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-archive"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasPermission("edit-task")
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.trans(
                                                      "task.edit_task"
                                                    ),
                                                    expression:
                                                      "trans('task.edit_task')"
                                                  }
                                                ],
                                                staticClass:
                                                  "btn btn-info btn-sm",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.editTask(task)
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-pencil-alt"
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasPermission("delete-task")
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "confirm",
                                                    rawName: "v-confirm",
                                                    value: {
                                                      ok: _vm.confirmDelete(
                                                        task
                                                      )
                                                    },
                                                    expression:
                                                      "{ok: confirmDelete(task)}"
                                                  },
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.trans(
                                                      "task.delete_task"
                                                    ),
                                                    expression:
                                                      "trans('task.delete_task')"
                                                  }
                                                ],
                                                key: task.id,
                                                staticClass:
                                                  "btn btn-danger btn-sm"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-trash"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.tasks.total
                      ? _c(
                          "module-info",
                          {
                            attrs: {
                              module: "task",
                              title: "module_info_title",
                              description: "module_info_description",
                              icon: "tasks"
                            }
                          },
                          [
                            _c("div", { attrs: { slot: "btn" }, slot: "btn" }, [
                              !_vm.showCreatePanel
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info btn-md",
                                      on: {
                                        click: function($event) {
                                          _vm.showCreatePanel = !_vm.showCreatePanel
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-plus" }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.trans("general.add_new"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("pagination-record", {
                      attrs: {
                        "page-length": _vm.filterTaskForm.page_length,
                        records: _vm.tasks
                      },
                      on: {
                        "update:pageLength": function($event) {
                          return _vm.$set(
                            _vm.filterTaskForm,
                            "page_length",
                            $event
                          )
                        },
                        "update:page-length": function($event) {
                          return _vm.$set(
                            _vm.filterTaskForm,
                            "page_length",
                            $event
                          )
                        },
                        updateRecords: _vm.getTasks
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }