var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("general.support")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("general.support")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("general.support")))
            ]),
            _vm._v(" "),
            _vm.product.name && !_vm.checkSupportValidity
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("Your support is expired. Please renew your support.")
                ])
              : _c("div", [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.support_tips) }
                  }),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        },
                        keydown: function($event) {
                          return _vm.supportForm.errors.clear(
                            $event.target.name
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.supportForm.subject,
                                expression: "supportForm.subject"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              value: "",
                              name: "subject",
                              placeholder: "Subject"
                            },
                            domProps: { value: _vm.supportForm.subject },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.supportForm,
                                  "subject",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.supportForm,
                              "prop-name": "subject"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("autosize-textarea", {
                            staticClass: "form-control",
                            attrs: {
                              rows: "5",
                              placeholder: "Body",
                              name: "body"
                            },
                            model: {
                              value: _vm.supportForm.body,
                              callback: function($$v) {
                                _vm.$set(_vm.supportForm, "body", $$v)
                              },
                              expression: "supportForm.body"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.supportForm,
                              "prop-name": "body"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._m(0)
                    ]
                  )
                ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("general.product_information")))
              ]),
              _vm._v(" "),
              _c("product", { attrs: { product: _vm.product } })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light m-t-10",
          attrs: { type: "submit" }
        },
        [_vm._v("Submit")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }