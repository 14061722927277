var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("message.message")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/message" } }, [
                _vm._v(_vm._s(_vm.trans("message.message")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("message.inbox")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("message-sidebar", {
                  attrs: { menu: "", statistics: _vm.statistics }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "col-10 col-lg-10 col-md-10" }, [
                  _c("img", {
                    staticClass: "message-avatar pull-left m-r-10",
                    attrs: {
                      src: _vm.getAvatar(_vm.message.user_from),
                      alt: "user"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "pull-right" }, [
                    _c("i", { staticClass: "far fa-clock" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("momentDateTime")(_vm.message.created_at))
                    )
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "card-title" }, [
                    _vm.isImportant(_vm.message)
                      ? _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.trans("message.important"),
                              expression: "trans('message.important')"
                            }
                          ],
                          staticClass:
                            "fas fa-star fa-lg starred custom-button",
                          on: {
                            click: function($event) {
                              return _vm.toggleImportant(_vm.message)
                            }
                          }
                        })
                      : _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.trans("message.mark_important"),
                              expression: "trans('message.mark_important')"
                            }
                          ],
                          staticClass: "far fa-star fa-lg custom-button",
                          on: {
                            click: function($event) {
                              return _vm.toggleImportant(_vm.message)
                            }
                          }
                        }),
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.message.subject) +
                        "\n                            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "confirm",
                          rawName: "v-confirm",
                          value: { ok: _vm.confirmDelete(_vm.message) },
                          expression: "{ok: confirmDelete(message)}"
                        }
                      ],
                      key: _vm.message.id,
                      staticClass: "btn btn-danger btn-sm pull-right"
                    },
                    [_c("i", { staticClass: "fas fa-trash" })]
                  ),
                  _vm._v(" "),
                  _vm.message
                    ? _c("p", { staticClass: "text-strong" }, [
                        _vm._v(
                          _vm._s(
                            _vm.message.user_from.email +
                              " " +
                              _vm.trans("message.to") +
                              " " +
                              _vm.message.user_to.email
                          ) + " "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.message.body) }
                  }),
                  _vm._v(" "),
                  _vm.message.has_attachment
                    ? _c("div", [
                        _c(
                          "ul",
                          {
                            staticClass: "m-t-10",
                            staticStyle: { "list-style": "none", padding: "0" }
                          },
                          _vm._l(_vm.attachments, function(attachment) {
                            return _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/message/" +
                                      _vm.message.uuid +
                                      "/attachment/" +
                                      attachment.uuid +
                                      "/download?token=" +
                                      _vm.authToken
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-paperclip" }),
                                  _vm._v(" " + _vm._s(attachment.user_filename))
                                ]
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "m-l-40" },
                    _vm._l(_vm.replies, function(reply) {
                      return _c("div", { staticClass: "m-t-20" }, [
                        _c("img", {
                          staticClass: "message-avatar pull-left m-r-10",
                          attrs: {
                            src: _vm.getAvatar(reply.user_from),
                            alt: "user"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "pull-right" }, [
                          _c("i", { staticClass: "far fa-clock" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("momentDateTime")(reply.created_at))
                          )
                        ]),
                        _vm._v(" "),
                        _c("h4", { staticClass: "card-title" }, [
                          _vm.isImportant(reply)
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.trans("message.important"),
                                    expression: "trans('message.important')"
                                  }
                                ],
                                staticClass:
                                  "fas fa-star fa-lg starred custom-button",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleImportant(reply)
                                  }
                                }
                              })
                            : _c("i", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.trans("message.mark_important"),
                                    expression:
                                      "trans('message.mark_important')"
                                  }
                                ],
                                staticClass: "far fa-star fa-lg custom-button",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleImportant(reply)
                                  }
                                }
                              }),
                          _vm._v(
                            "\n                                        " +
                              _vm._s(reply.subject) +
                              "\n                                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "confirm",
                                  rawName: "v-confirm",
                                  value: { ok: _vm.confirmDelete(reply) },
                                  expression: "{ok: confirmDelete(reply)}"
                                }
                              ],
                              key: reply.id,
                              staticClass: "btn btn-danger btn-sm pull-right"
                            },
                            [_c("i", { staticClass: "fas fa-trash" })]
                          ),
                          _vm._v(" "),
                          reply
                            ? _c("p", { staticClass: "text-strong" }, [
                                _vm._v(
                                  _vm._s(
                                    reply.user_from.email +
                                      " " +
                                      _vm.trans("message.to") +
                                      " " +
                                      reply.user_to.email
                                  ) + " "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(reply.body) }
                        }),
                        _vm._v(" "),
                        reply.has_attachment
                          ? _c("div", [
                              _c(
                                "ul",
                                {
                                  staticClass: "m-t-10",
                                  staticStyle: {
                                    "list-style": "none",
                                    padding: "0"
                                  }
                                },
                                _vm._l(
                                  _vm.reply_attachments[reply.id],
                                  function(attachment) {
                                    return _c("li", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "/message/" +
                                              reply.uuid +
                                              "/attachment/" +
                                              attachment.uuid +
                                              "/download?token=" +
                                              _vm.authToken
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-paperclip"
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(attachment.user_filename)
                                          )
                                        ]
                                      )
                                    ])
                                  }
                                ),
                                0
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$last(reply, _vm.replies) ? _c("hr") : _vm._e()
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("h4", { staticClass: "card-title m-t-40" }, [
                    _vm._v(_vm._s(_vm.trans("message.reply")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        },
                        keydown: function($event) {
                          return _vm.replyForm.errors.clear($event.target.name)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("html-editor", {
                            attrs: {
                              name: "body",
                              model: _vm.replyForm.body,
                              isUpdate: false
                            },
                            on: {
                              "update:model": function($event) {
                                return _vm.$set(_vm.replyForm, "body", $event)
                              },
                              clearErrors: function($event) {
                                return _vm.replyForm.errors.clear("body")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.replyForm,
                              "prop-name": "body"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("file-upload-input", {
                            attrs: {
                              "button-text": _vm.trans("message.attachment"),
                              token: _vm.replyForm.upload_token,
                              module: "message",
                              "clear-file": _vm.clear_message_attachment,
                              "module-id": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group pull-right" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-success waves-effect waves-light",
                            attrs: { type: "submit" }
                          },
                          [
                            _c("i", { staticClass: "fas fa-paper-plane" }),
                            _vm._v(" " + _vm._s(_vm.trans("message.send")))
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }