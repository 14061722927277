var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "alert alert-info" }, [
        _c("i", { staticClass: "fas fa-exclamation-triangle" }),
        _vm._v(" " + _vm._s(_vm.trans("task.tip_task_attachment")))
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "pull-right btn btn-sm btn-rounded btn-success",
          attrs: {
            "data-toggle": "modal",
            "data-target": ".task-attachment-form"
          }
        },
        [_vm._v(_vm._s(_vm.trans("task.add_new_attachment")))]
      ),
      _vm._v(" "),
      _c("task-attachment-form", {
        attrs: { uuid: _vm.uuid, auuid: _vm.editAttachmentUuid },
        on: { completed: _vm.getAttachments, loaded: _vm.reset }
      }),
      _vm._v(" "),
      _c("task-attachment-detail", {
        attrs: { uuid: _vm.uuid, auuid: _vm.showAttachmentUuid },
        on: { loaded: _vm.reset }
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "card-title m-t-20" }, [
        _vm._v(_vm._s(_vm.trans("task.task_attachment_list")))
      ]),
      _vm._v(" "),
      _vm.task_attachments
        ? _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(
              _vm._s(
                _vm.trans("general.total_result_found", {
                  count: _vm.task_attachments.total
                })
              )
            )
          ])
        : _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(_vm._s(_vm.trans("general.no_result_found")))
          ]),
      _vm._v(" "),
      _vm.task_attachments.total
        ? _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.trans("task.task_attachment_title")))
                  ]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.owner")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.created_at")))]),
                  _vm._v(" "),
                  _c("th", { staticClass: "table-option" }, [
                    _vm._v(_vm._s(_vm.trans("general.action")))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.task_attachments.data, function(task_attachment) {
                  return _c("tr", [
                    _c("td", {
                      domProps: { textContent: _vm._s(task_attachment.title) }
                    }),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          task_attachment.user.profile.first_name +
                            " " +
                            task_attachment.user.profile.last_name
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("momentDateTime")(task_attachment.created_at)
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "table-option" }, [
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.trans("task.view_task_attachment"),
                                  expression:
                                    "trans('task.view_task_attachment')"
                                }
                              ],
                              staticClass: "btn btn-success btn-sm",
                              attrs: {
                                "data-toggle": "modal",
                                "data-target": ".task-attachment-detail"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showAttachment(task_attachment)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-arrow-circle-right"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          task_attachment.user_id === _vm.getAuthUser("id")
                            ? [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.trans(
                                          "task.edit_task_attachment"
                                        ),
                                        expression:
                                          "trans('task.edit_task_attachment')"
                                      }
                                    ],
                                    staticClass: "btn btn-info btn-sm",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": ".task-attachment-form"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editAttachment(
                                          task_attachment
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-pencil-alt"
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "confirm",
                                        rawName: "v-confirm",
                                        value: {
                                          ok: _vm.ConfirmDeleteAttachment(
                                            task_attachment
                                          )
                                        },
                                        expression:
                                          "{ok: ConfirmDeleteAttachment(task_attachment)}"
                                      },
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.trans(
                                          "task.delete_task_attachment"
                                        ),
                                        expression:
                                          "trans('task.delete_task_attachment')"
                                      }
                                    ],
                                    key: task_attachment.id,
                                    staticClass: "btn btn-danger btn-sm"
                                  },
                                  [_c("i", { staticClass: "fas fa-trash" })]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("pagination-record", {
        attrs: {
          "page-length": _vm.filterAttachmentForm.page_length,
          records: _vm.task_attachments
        },
        on: {
          "update:pageLength": function($event) {
            return _vm.$set(_vm.filterAttachmentForm, "page_length", $event)
          },
          "update:page-length": function($event) {
            return _vm.$set(_vm.filterAttachmentForm, "page_length", $event)
          },
          updateRecords: _vm.getAttachments
        },
        nativeOn: {
          change: function($event) {
            return _vm.getAttachments($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }