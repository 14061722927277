<template>
    <div class="right-sidebar">
    </div>
</template>

<script>
    export default {
        mounted() {
        }
    }
</script>
