var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed($event)
        },
        keydown: function($event) {
          return _vm.taskForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("task.title")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.taskForm.title,
                    expression: "taskForm.title"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "title",
                  placeholder: _vm.trans("task.title")
                },
                domProps: { value: _vm.taskForm.title },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.taskForm, "title", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.taskForm, "prop-name": "title" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.trans("task.task_category")))
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: {
                      label: "name",
                      name: "task_category_id",
                      id: "task_category_id",
                      options: _vm.task_categories,
                      placeholder: _vm.trans("task.select_task_category")
                    },
                    on: {
                      select: _vm.onTaskCategorySelect,
                      close: function($event) {
                        return _vm.taskForm.errors.clear("task_category_id")
                      },
                      remove: function($event) {
                        _vm.taskForm.task_category_id = ""
                      }
                    },
                    model: {
                      value: _vm.selected_task_category,
                      callback: function($$v) {
                        _vm.selected_task_category = $$v
                      },
                      expression: "selected_task_category"
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.taskForm,
                      "prop-name": "task_category_id"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.trans("task.task_priority")))
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: {
                      label: "name",
                      name: "task_priority_id",
                      id: "task_priority_id",
                      options: _vm.task_priorities,
                      placeholder: _vm.trans("task.select_task_priority")
                    },
                    on: {
                      select: _vm.onTaskPrioritySelect,
                      close: function($event) {
                        return _vm.taskForm.errors.clear("task_priority_id")
                      },
                      remove: function($event) {
                        _vm.taskForm.task_priority_id = ""
                      }
                    },
                    model: {
                      value: _vm.selected_task_priority,
                      callback: function($$v) {
                        _vm.selected_task_priority = $$v
                      },
                      expression: "selected_task_priority"
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.taskForm,
                      "prop-name": "task_priority_id"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("task.question_set")))
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  label: "name",
                  name: "question_set_id",
                  id: "question_set_id",
                  options: _vm.question_sets,
                  placeholder: _vm.trans("task.select_question_set")
                },
                on: {
                  select: _vm.onQuestionSetSelect,
                  close: function($event) {
                    return _vm.taskForm.errors.clear("question_set_id")
                  },
                  remove: function($event) {
                    _vm.taskForm.question_set_id = ""
                  }
                },
                model: {
                  value: _vm.selected_question_set,
                  callback: function($$v) {
                    _vm.selected_question_set = $$v
                  },
                  expression: "selected_question_set"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.taskForm,
                  "prop-name": "question_set_id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    { attrs: { for: "" } },
                    [
                      _vm._v(_vm._s(_vm.trans("task.start_date")) + " "),
                      _c("show-tip", {
                        attrs: {
                          module: "task",
                          tip: "tip_task_start_date",
                          type: "field"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("datepicker", {
                    attrs: {
                      bootstrapStyling: true,
                      placeholder: _vm.trans("task.start_date")
                    },
                    on: {
                      selected: function($event) {
                        return _vm.taskForm.errors.clear("start_date")
                      }
                    },
                    model: {
                      value: _vm.taskForm.start_date,
                      callback: function($$v) {
                        _vm.$set(_vm.taskForm, "start_date", $$v)
                      },
                      expression: "taskForm.start_date"
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.taskForm,
                      "prop-name": "start_date"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    { attrs: { for: "" } },
                    [
                      _vm._v(_vm._s(_vm.trans("task.due_date")) + " "),
                      _c("show-tip", {
                        attrs: {
                          module: "task",
                          tip: "tip_task_due_date",
                          type: "field"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("datepicker", {
                    attrs: {
                      bootstrapStyling: true,
                      placeholder: _vm.trans("task.due_date")
                    },
                    on: {
                      selected: function($event) {
                        return _vm.taskForm.errors.clear("due_date")
                      }
                    },
                    model: {
                      value: _vm.taskForm.due_date,
                      callback: function($$v) {
                        _vm.$set(_vm.taskForm, "due_date", $$v)
                      },
                      expression: "taskForm.due_date"
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.taskForm,
                      "prop-name": "due_date"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                { attrs: { for: "" } },
                [
                  _vm._v(_vm._s(_vm.trans("user.user")) + " "),
                  _c("show-tip", {
                    attrs: {
                      module: "task",
                      tip: "tip_assigned_user",
                      type: "field"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  label: "name",
                  "track-by": "id",
                  name: "user_id",
                  id: "user_id",
                  options: _vm.users,
                  placeholder: _vm.trans("user.select_user"),
                  multiple: true,
                  "close-on-select": false,
                  "clear-on-select": false,
                  "hide-selected": true,
                  selected: _vm.selected_users
                },
                on: { select: _vm.onUserSelect, remove: _vm.onUserRemove },
                model: {
                  value: _vm.selected_users,
                  callback: function($$v) {
                    _vm.selected_users = $$v
                  },
                  expression: "selected_users"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.taskForm, "prop-name": "user_id" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("file-upload-input", {
                attrs: {
                  "button-text": _vm.trans("general.upload_document"),
                  token: _vm.taskForm.upload_token,
                  module: "task",
                  "clear-file": _vm.clearTaskAttachment,
                  "module-id": _vm.module_id || ""
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.uuid
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("switches", {
                    staticClass: "m-l-20",
                    attrs: { theme: "bootstrap", color: "success" },
                    model: {
                      value: _vm.taskForm.send_task_assign_email,
                      callback: function($$v) {
                        _vm.$set(_vm.taskForm, "send_task_assign_email", $$v)
                      },
                      expression: "taskForm.send_task_assign_email"
                    }
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.trans("task.send_task_assign_email")) + " "
                  ),
                  _c("show-tip", {
                    attrs: {
                      module: "task",
                      tip: "tip_send_task_assign_email",
                      type: "field"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-md-6" },
          [
            _c("html-editor", {
              attrs: {
                name: "description",
                model: _vm.taskForm.description,
                isUpdate: _vm.uuid ? true : false
              },
              on: {
                "update:model": function($event) {
                  return _vm.$set(_vm.taskForm, "description", $event)
                },
                clearErrors: function($event) {
                  return _vm.taskForm.errors.clear("description")
                }
              }
            }),
            _vm._v(" "),
            _c("show-error", {
              attrs: { "form-name": _vm.taskForm, "prop-name": "description" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light",
          attrs: { type: "submit" }
        },
        [
          _vm.uuid
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uuid,
              expression: "uuid"
            }
          ],
          staticClass: "btn btn-danger waves-effect waves-light",
          attrs: { to: "/task" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }