var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "alert alert-info" }, [
        _c("i", { staticClass: "fas fa-exclamation-triangle" }),
        _vm._v(" " + _vm._s(_vm.trans("task.tip_task_sign_off")))
      ]),
      _vm._v(" "),
      _vm.task.user_id == _vm.getAuthUser("id") &&
      (_vm.task.sign_off_status == "requested" ||
        _vm.task.sign_off_status === "approved")
        ? [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("task.sign_off_action")))
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.signOffAction($event)
                  },
                  keydown: function($event) {
                    return _vm.taskSignOffForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskSignOffForm.sign_off_remarks,
                          expression: "taskSignOffForm.sign_off_remarks"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        value: "",
                        rows: "2",
                        name: "sign_off_remarks",
                        placeholder: _vm.trans("task.sign_off_remarks")
                      },
                      domProps: { value: _vm.taskSignOffForm.sign_off_remarks },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.taskSignOffForm,
                            "sign_off_remarks",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.taskSignOffForm,
                        "prop-name": "sign_off_remarks"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm.task.sign_off_status === "requested"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-info waves-effect waves-light",
                          attrs: { type: "button" },
                          on: { click: _vm.signOffActionApprove }
                        },
                        [_vm._v(_vm._s(_vm.trans("task.approve")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger waves-effect waves-light",
                      attrs: { type: "button" },
                      on: { click: _vm.signOffActionReject }
                    },
                    [_vm._v(_vm._s(_vm.trans("task.reject")))]
                  )
                ])
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.users.indexOf(_vm.getAuthUser("id")) > -1 &&
      _vm.task.sign_off_status != "approved"
        ? [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("task.sign_off")))
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.signOff($event)
                  },
                  keydown: function($event) {
                    return _vm.taskSignOffForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskSignOffForm.sign_off_remarks,
                          expression: "taskSignOffForm.sign_off_remarks"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        value: "",
                        rows: "2",
                        name: "sign_off_remarks",
                        placeholder: _vm.trans("task.sign_off_remarks")
                      },
                      domProps: { value: _vm.taskSignOffForm.sign_off_remarks },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.taskSignOffForm,
                            "sign_off_remarks",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.taskSignOffForm,
                        "prop-name": "sign_off_remarks"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm.task.sign_off_status === null ||
                  _vm.task.sign_off_status === "rejected" ||
                  _vm.task.sign_off_status === "cancelled"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-info waves-effect waves-light",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(_vm._s(_vm.trans("task.request")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.task.sign_off_status === "requested"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-danger waves-effect waves-light",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(_vm._s(_vm.trans("task.cancel_request")))]
                      )
                    : _vm._e()
                ])
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("h4", { staticClass: "card-title m-t-20" }, [
        _vm._v(_vm._s(_vm.trans("task.sign_off_log")))
      ]),
      _vm._v(" "),
      _vm.task_sign_off_logs
        ? _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(
              _vm._s(
                _vm.trans("general.total_result_found", {
                  count: _vm.task_sign_off_logs.total
                })
              )
            )
          ])
        : _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(_vm._s(_vm.trans("general.no_result_found")))
          ]),
      _vm._v(" "),
      _vm.task_sign_off_logs.total
        ? _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.trans("user.user")))]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(_vm._s(_vm.trans("task.sign_off_remarks")))
                  ]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.status")))]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.trans("task.created_at")))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.task_sign_off_logs.data, function(
                  task_sign_off_log
                ) {
                  return _c("tr", [
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          task_sign_off_log.user_added.profile.first_name +
                            " " +
                            task_sign_off_log.user_added.profile.last_name
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(task_sign_off_log.remarks)
                      }
                    }),
                    _vm._v(" "),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.trans("task." + task_sign_off_log.status)
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("momentDateTime")(task_sign_off_log.created_at)
                        )
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("pagination-record", {
        attrs: {
          "page-length": _vm.filterTaskSignOffRequestForm.page_length,
          records: _vm.task_sign_off_logs
        },
        on: {
          "update:pageLength": function($event) {
            return _vm.$set(
              _vm.filterTaskSignOffRequestForm,
              "page_length",
              $event
            )
          },
          "update:page-length": function($event) {
            return _vm.$set(
              _vm.filterTaskSignOffRequestForm,
              "page_length",
              $event
            )
          },
          updateRecords: _vm.getTaskSignOffRequests
        },
        nativeOn: {
          change: function($event) {
            return _vm.getTaskSignOffRequests($event)
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }