var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasPermission("create-sub-task")
        ? _c(
            "button",
            {
              staticClass: "pull-right btn btn-sm btn-rounded btn-success",
              attrs: { "data-toggle": "modal", "data-target": ".sub-task-form" }
            },
            [_vm._v(_vm._s(_vm.trans("task.add_new_sub_task")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("sub-task-form", {
        attrs: { uuid: _vm.uuid, suuid: _vm.editSubTaskUuid },
        on: { completed: _vm.subTaskComplete, loaded: _vm.reset }
      }),
      _vm._v(" "),
      _c("sub-task-detail", {
        attrs: { uuid: _vm.uuid, suuid: _vm.showSubTaskUuid },
        on: { updateStatus: _vm.updateTaskProgress, loaded: _vm.reset }
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "card-title m-t-20" }, [
        _vm._v(_vm._s(_vm.trans("task.sub_task_list")))
      ]),
      _vm._v(" "),
      _vm.subTasks
        ? _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(
              _vm._s(
                _vm.trans("general.total_result_found", {
                  count: _vm.subTasks.total
                })
              )
            )
          ])
        : _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(_vm._s(_vm.trans("general.no_result_found")))
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-responsive" }, [
        _vm.subTasks.total
          ? _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.sub_task_title")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.sub_task_status")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.owner")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.created_at")))]),
                  _vm._v(" "),
                  _c("th", { staticClass: "table-option" }, [
                    _vm._v(_vm._s(_vm.trans("general.action")))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.subTasks.data, function(subTask) {
                  return _c("tr", [
                    _c("td", {
                      domProps: { textContent: _vm._s(subTask.title) }
                    }),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "span",
                        {
                          class: [
                            "label",
                            "label-" + _vm.getSubTaskStatus(subTask).color
                          ]
                        },
                        [_vm._v(_vm._s(_vm.getSubTaskStatus(subTask).label))]
                      )
                    ]),
                    _vm._v(" "),
                    subTask.user_added
                      ? _c("td", [
                          _vm._v(
                            _vm._s(
                              subTask.user_added.profile.first_name +
                                " " +
                                subTask.user_added.profile.last_name
                            )
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm._f("momentDateTime")(subTask.created_at))
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "table-option" }, [
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.trans("task.view_sub_task"),
                                  expression: "trans('task.view_sub_task')"
                                }
                              ],
                              staticClass: "btn btn-success btn-sm",
                              attrs: {
                                "data-toggle": "modal",
                                "data-target": ".sub-task-detail"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showSubTask(subTask)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-arrow-circle-right"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _vm.hasRole("admin") ||
                          _vm.hasPermission("access-all-task") ||
                          subTask.task.user_id === _vm.getAuthUser("id") ||
                          subTask.user_id === _vm.getAuthUser("id")
                            ? [
                                _vm.hasPermission("edit-sub-task")
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "task.edit_sub_task"
                                            ),
                                            expression:
                                              "trans('task.edit_sub_task')"
                                          }
                                        ],
                                        staticClass: "btn btn-info btn-sm",
                                        attrs: {
                                          "data-toggle": "modal",
                                          "data-target": ".sub-task-form"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editSubTask(subTask)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-pencil-alt"
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasPermission("delete-sub-task")
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDeleteSubTask(
                                                subTask
                                              )
                                            },
                                            expression:
                                              "{ok: confirmDeleteSubTask(subTask)}"
                                          },
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "task.delete_sub_task"
                                            ),
                                            expression:
                                              "trans('task.delete_sub_task')"
                                          }
                                        ],
                                        key: subTask.id,
                                        staticClass: "btn btn-danger btn-sm"
                                      },
                                      [_c("i", { staticClass: "fas fa-trash" })]
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("pagination-record", {
        attrs: {
          "page-length": _vm.filterSubTaskForm.page_length,
          records: _vm.subTasks
        },
        on: {
          "update:pageLength": function($event) {
            return _vm.$set(_vm.filterSubTaskForm, "page_length", $event)
          },
          "update:page-length": function($event) {
            return _vm.$set(_vm.filterSubTaskForm, "page_length", $event)
          },
          updateRecords: _vm.getSubTasks
        },
        nativeOn: {
          change: function($event) {
            return _vm.getSubTasks($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }