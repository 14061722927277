<template>
    <div>
        <div class="row page-titles">
            <div class="col-md-6 col-8 align-self-center">
                <h3 class="text-themecolor m-b-0 m-t-0">{{trans('general.report')}}</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/home">{{trans('general.home')}}</router-link></li>
                    <li class="breadcrumb-item active">Log report</li>
                </ol>
            </div>
             <div class="col-md-6 col-8 align-right" style="float: right;">
                <ol class="breadcrumb">
                <button @click="excel" type="button" class="btn btn-info btn-md pull-right" style="margin-left: auto; display: block;" >
                    Excel
                </button>
            </ol>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                       
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>User Email</th>
                                        <th>Name</th>
                                        <th>Branch</th>
                                        <th>Date & Time</th>
                                        <th>Late Mark</th>
                                        <th>Working Hours</th>
                                        <th>Break</th>
                                        <th>First Checkin</th>
                                        <th>Last Checkout</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>
                                        <input class="form-control"  type="text" v-model="general_filter.email" @keyup.enter="getReport()">
                                    </td>
                                    <td>
                                        <input class="form-control"  type="text" v-model="general_filter.name" @keyup.enter="getReport()">
                                    </td>
                                    <td></td>
                                    
                                    <td>
                                        <input class="form-control"  type="date" v-model="general_filter.date" @keyup.enter="getReport()">
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tbody>
                                    <tr v-for="report in reports">
                                        <td>{{report.user && report.user.email}}</td>
                                        <td>{{report.user && report.user.profile.first_name}} {{report.user && report.user.profile.last_name}}</td>
                                        <td>{{report.user && report.user.profile.location.name}}</td>
                                        <td>{{report.created_date}}</td>
                                        <td>{{report.temp.in_out.late_mark}}</td>
                                        <td>{{report.w_hours}}</td>
                                        <td>{{report.difference}}</td>
                                        <td>{{report.temp.checked_in}}</td>
                                        <td>{{report.temp.checked_out}}</td>
                                       <!--  <td>{{report.api_request && report.api_request.input.type}}</td> -->

<!--                                         <td>{{report.api_request && report.api_request.input.device_id}}</td>
 -->                                        
                                        
                                    </tr>
                                    
                                </tbody>
                            </table>
                           <!--  <div class="card-body">
                                <div class="row">
                                    <iframe :src="report.source" width="100%" height="400" type="application/pdf" frameborder="0"></iframe>
                                </div>
                            </div> -->
                            <!-- <a
                                type="button"
                                href="/api/report/log/summary"
                                download="file.xlsx"
                            >
                                <button
                                    @click="export_l()"
                                    class="btn btn-primary"
                                >
                                Export
                                </button> -->

                               <!--  <button @click="excel" type="button" class="btn btn-xs btn-primary">
                                    Excel
                                </button> -->
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components : {},
        data() {
            return {
                reports: {},
                general_filter:{
                    date:'',
                    name:'',
                    email:'',
                   
                },
                 report: {
                    from_date:'',
                    to_date:'',
                    role:'',
                    experiance_min:'',
                    experiance_max:'',
                    qualification:'',
                    salary_min:'',
                    salary_max:'',
                    work_shift:'',
                    gender:'',
                    age_min:'',
                    age_max:'',
                    job_role:'',
                    job_type:[],
                    profile_freshness:'',
                    preferred_location:'',
                    display_type:'display',
                    report_type:'Brief Report',
                    shortlist:'',
                    result:'',
                    source:'',
                },
            };
        },
        mounted(){
            this.getReport();
        },
        methods: {
            getReport(){
                let general_filter=this.general_filter
                axios.post('/api/report/log/summary',{general_filter})
                    .then(response => response.data)
                    .then(response => this.reports = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
                     console.log('hjhjhjjji',this.reports)
            },

            excel() {
                
             axios.get('/api/report/log/excel_summary',
                   {responseType: 'arraybuffer'})
               .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    console.log(fileURL)
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'attendance-report.xls');
                   document.body.appendChild(fileLink);
                   fileLink.click();

                })

            },
            getUserNameWithDesignationAndDepartment(user){
                return helper.getUserNameWithDesignationAndDepartment(user);
            },
            generateRatingStar(rating){
                return helper.generateRatingStar(rating);
            }
        },
        watch: {
        }
    }
</script>