var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("task.question")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", { attrs: { menu: "question" } }),
                _vm._v(" "),
                _c("div", { staticClass: "col-10 col-lg-10 col-md-10" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-sm-6 col-md-6" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("task.add_new_question_set")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submit($event)
                            },
                            keydown: function($event) {
                              return _vm.questionForm.errors.clear(
                                $event.target.name
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("task.question_set_name"))
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.questionForm.name,
                                    expression: "questionForm.name"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "name",
                                  placeholder: _vm.trans(
                                    "task.question_set_name"
                                  )
                                },
                                domProps: { value: _vm.questionForm.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.questionForm,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.questionForm,
                                  "prop-name": "name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("task.question_set_description")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.questionForm.description,
                                    expression: "questionForm.description"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  value: "",
                                  rows: "2",
                                  name: "description",
                                  placeholder: _vm.trans(
                                    "task.question_set_description"
                                  )
                                },
                                domProps: {
                                  value: _vm.questionForm.description
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.questionForm,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.questionForm,
                                  "prop-name": "description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.trans("task.add_new_question"),
                                    expression: "trans('task.add_new_question')"
                                  }
                                ],
                                staticClass:
                                  "btn btn-info waves-effect waves-light pull-right btn-sm",
                                attrs: { type: "button" },
                                on: { click: _vm.addQuestion }
                              },
                              [_c("i", { staticClass: "fas fa-plus" })]
                            ),
                            _vm._v(" "),
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v(_vm._s(_vm.trans("task.add_new_question")))
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.questionForm.questions, function(
                              question,
                              index
                            ) {
                              return _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("task.question")) +
                                        " " +
                                        _vm._s(index + 1)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "confirm",
                                          rawName: "v-confirm",
                                          value: {
                                            ok: _vm.confirmRemoveQuestion(
                                              question
                                            )
                                          },
                                          expression:
                                            "{ok: confirmRemoveQuestion(question)}"
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.trans(
                                            "task.remove_question"
                                          ),
                                          expression:
                                            "trans('task.remove_question')"
                                        }
                                      ],
                                      key: index,
                                      staticClass:
                                        "btn btn-danger waves-effect waves-light pull-right btn-sm",
                                      attrs: { type: "button" }
                                    },
                                    [_c("i", { staticClass: "fas fa-trash" })]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: question.question,
                                        expression: "question.question"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      value: "",
                                      name: "question_" + index,
                                      placeholder: _vm.trans("task.question")
                                    },
                                    domProps: { value: question.question },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          question,
                                          "question",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.questionForm,
                                      "prop-name": "question_" + index
                                    }
                                  })
                                ],
                                1
                              )
                            })
                          ],
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-info waves-effect waves-light pull-right",
                              attrs: { type: "submit" }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.trans("general.save")))
                              ])
                            ]
                          )
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6 col-md-6" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("task.question_set_list")))
                        ]),
                        _vm._v(" "),
                        _vm.question_sets
                          ? _c("h6", { staticClass: "card-subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("general.total_result_found", {
                                    count: _vm.question_sets.total
                                  })
                                )
                              )
                            ])
                          : _c("h6", { staticClass: "card-subtitle" }, [
                              _vm._v(
                                _vm._s(_vm.trans("general.no_result_found"))
                              )
                            ]),
                        _vm._v(" "),
                        _vm.question_sets.total
                          ? _c("div", { staticClass: "table-responsive" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("task.question_set_name")
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "table-option" }, [
                                      _vm._v("Action")
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.question_sets.data, function(
                                    question_set
                                  ) {
                                    return _c("tr", [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(question_set.name)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "task.view_question_set"
                                                      ),
                                                      expression:
                                                        "trans('task.view_question_set')"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn btn-info btn-sm",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "modal",
                                                    "data-target":
                                                      ".question-set-detail"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.fetchQuestionSet(
                                                        question_set
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-arrow-circle-right"
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "confirm",
                                                      rawName: "v-confirm",
                                                      value: {
                                                        ok: _vm.confirmDelete(
                                                          question_set
                                                        )
                                                      },
                                                      expression:
                                                        "{ok: confirmDelete(question_set)}"
                                                    },
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "question_set.delete_role"
                                                      ),
                                                      expression:
                                                        "trans('question_set.delete_role')"
                                                    }
                                                  ],
                                                  key: question_set.id,
                                                  staticClass:
                                                    "btn btn-danger btn-sm"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-trash"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("pagination-record", {
                          attrs: {
                            "page-length":
                              _vm.filterQuestionSetForm.page_length,
                            records: _vm.question_sets
                          },
                          on: {
                            "update:pageLength": function($event) {
                              return _vm.$set(
                                _vm.filterQuestionSetForm,
                                "page_length",
                                $event
                              )
                            },
                            "update:page-length": function($event) {
                              return _vm.$set(
                                _vm.filterQuestionSetForm,
                                "page_length",
                                $event
                              )
                            },
                            updateRecords: _vm.getQuestionSets
                          },
                          nativeOn: {
                            change: function($event) {
                              return _vm.getQuestionSets($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade question-set-detail",
        staticStyle: { display: "none" },
        attrs: {
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "questionSetDetail",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h4",
                {
                  staticClass: "modal-title",
                  attrs: { id: "questionSetDetail" }
                },
                [_vm._v(_vm._s(_vm.trans("task.question_set")))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-hidden": "true"
                  }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _vm.question_set
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.question_set.name) +
                        "\n                        "
                    ),
                    _c("span", { staticClass: "pull-right" }, [
                      _vm._v(
                        _vm._s(_vm.trans("task.created_at")) +
                          " " +
                          _vm._s(_vm._f("moment")(_vm.question_set.created_at))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.question_set.description))]),
                  _vm._v(" "),
                  _c("h4", [_vm._v(_vm._s(_vm.trans("task.questions")))]),
                  _vm._v(" "),
                  _c(
                    "ol",
                    _vm._l(_vm.question_set.questions, function(question) {
                      return _c("li", {
                        domProps: { textContent: _vm._s(question.question) }
                      })
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger waves-effect text-left",
                  attrs: { type: "button", "data-dismiss": "modal" }
                },
                [_vm._v(_vm._s(_vm.trans("general.close")))]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }