var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("task.task_detail")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/task" } }, [
                _vm._v(_vm._s(_vm.trans("task.task")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.task.title))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("div", { staticClass: "row m-b-5" }, [
            _c("div", { staticClass: "col-12 col-md-8 p-0" }, [
              _c(
                "div",
                { staticClass: "btn btn-group" },
                [
                  _vm.is_editable
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-warning btn-sm",
                          attrs: { to: "/task/" + _vm.task.uuid + "/edit" }
                        },
                        [
                          _c("i", { staticClass: "fas fa-pencil-alt" }),
                          _vm._v(" " + _vm._s(_vm.trans("general.edit")))
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.is_editable
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-info btn-sm",
                          on: { click: _vm.toggleCopyPanel }
                        },
                        [
                          _c("i", { staticClass: "far fa-file" }),
                          _vm._v(" " + _vm._s(_vm.trans("task.copy")))
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.is_editable
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-sm",
                          on: { click: _vm.toggleRecurringPanel }
                        },
                        [
                          _c("i", { staticClass: "fas fa-repeat" }),
                          _vm._v(" " + _vm._s(_vm.trans("task.recurring")))
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-4 p-0" }, [
              _c("div", { staticClass: "btn btn-group pull-right" }, [
                _vm.hasPermission("create-task")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-sm",
                        on: { click: _vm.createTask }
                      },
                      [
                        _c("i", { staticClass: "fas fa-plus" }),
                        _vm._v(" " + _vm._s(_vm.trans("task.add_new_task")))
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasPermission("delete-task")
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "confirm",
                            rawName: "v-confirm",
                            value: { ok: _vm.confirmDelete(_vm.task) },
                            expression: "{ok: confirmDelete(task)}"
                          }
                        ],
                        key: _vm.task.id,
                        staticClass: "btn btn-danger btn-sm"
                      },
                      [
                        _c("i", { staticClass: "fas fa-trash" }),
                        _vm._v(" " + _vm._s(_vm.trans("general.delete")))
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasAdminRole() ||
                _vm.hasPermission("access-all-task") ||
                _vm.task.user_id == _vm.getAuthUser("id")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-info btn-sm pull-right",
                        attrs: {
                          "data-toggle": "modal",
                          "data-target": ".task-config"
                        }
                      },
                      [_c("i", { staticClass: "fas fa-cog" })]
                    )
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.is_editable
                ? _c("task-recurring", {
                    attrs: {
                      uuid: _vm.uuid,
                      "show-panel": _vm.showRecurringPanel
                    },
                    on: {
                      toggle: _vm.toggleRecurringPanel,
                      recurringUpdated: _vm.getTask
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.is_editable
                ? _c("task-copy", {
                    attrs: { uuid: _vm.uuid, "show-panel": _vm.showCopyPanel },
                    on: { toggle: _vm.toggleCopyPanel }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("task-config", {
            attrs: { uuid: _vm.uuid, task: _vm.task },
            on: { completed: _vm.getTask }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3 col-xs-6 b-r" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right",
                      staticStyle: { cursor: "pointer" }
                    },
                    [
                      _c("i", {
                        class: [
                          "fa",
                          "fa-star",
                          "fa-2x",
                          _vm.isTaskStarred ? "starred" : ""
                        ],
                        on: {
                          click: function($event) {
                            return _vm.toggleStar(_vm.task)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.trans("task.number")))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v("#" + _vm._s(_vm.getTaskNumber(_vm.task)))
                  ]),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.trans("task.title")))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.task.title))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "user-profile pull-right" }, [
                    _c("div", { staticClass: "profile-img" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.getAvatar(_vm.task.user_added),
                          alt: "user"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.trans("task.owner")))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(
                      _vm._s(
                        _vm.task.user_added.profile.first_name +
                          " " +
                          _vm.task.user_added.profile.last_name
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 col-xs-6 b-r" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.trans("task.category")))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.task.task_category.name))
                  ]),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.trans("task.priority")))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.task.task_priority.name))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 col-xs-6 b-r" },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.trans("task.start_date")))
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm._f("moment")(_vm.task.start_date)))
                    ]),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(_vm.trans("task.due_date")))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm._f("moment")(_vm.task.due_date)))
                    ]),
                    _vm._v(" "),
                    _vm.task.completed_at &&
                    _vm.task.sign_off_status === "approved"
                      ? [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.trans("task.completed_at")))
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("momentDateTime")(_vm.task.completed_at)
                              )
                            )
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 col-xs-6" },
                  [
                    _vm._l(_vm.getTaskStatus(_vm.task), function(status) {
                      return _c(
                        "div",
                        {
                          staticClass: "ribbon-wrapper card",
                          staticStyle: {
                            "margin-right": "-15px",
                            "padding-top": "10px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                "ribbon",
                                "ribbon-" + status.color,
                                "ribbon-right"
                              ]
                            },
                            [_vm._v(_vm._s(status.label))]
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.task.is_recurring
                      ? _c(
                          "div",
                          {
                            staticClass: "ribbon-wrapper card",
                            staticStyle: {
                              "margin-right": "-15px",
                              "padding-top": "10px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  "ribbon",
                                  "ribbon-danger",
                                  "ribbon-right"
                                ],
                                staticStyle: { cursor: "pointer" },
                                on: { click: _vm.toggleRecurringPanel }
                              },
                              [
                                _c("i", { staticClass: "fas fa-repeat" }),
                                _vm._v(
                                  " " + _vm._s(_vm.trans("task.recurring"))
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.task.is_archived
                      ? _c(
                          "div",
                          {
                            staticClass: "ribbon-wrapper card",
                            staticStyle: {
                              "margin-right": "-15px",
                              "padding-top": "10px"
                            }
                          },
                          [
                            _vm.task.user_id == _vm.getAuthUser("id") &&
                            _vm.task.sign_off_status === "approved"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "confirm",
                                          rawName: "v-confirm",
                                          value: {
                                            ok: _vm.confirmToggleArchive(
                                              _vm.task
                                            )
                                          },
                                          expression:
                                            "{ok: confirmToggleArchive(task)}"
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.trans(
                                            "task.remove_from_archive"
                                          ),
                                          expression:
                                            "trans('task.remove_from_archive')"
                                        }
                                      ],
                                      key: "archive_" + _vm.task.id,
                                      class: [
                                        "ribbon",
                                        "ribbon-warning",
                                        "ribbon-right"
                                      ],
                                      staticStyle: { cursor: "pointer" }
                                    },
                                    [_vm._v(_vm._s(_vm.trans("task.archived")))]
                                  )
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "ribbon",
                                        "ribbon-warning",
                                        "ribbon-right"
                                      ]
                                    },
                                    [_vm._v(_vm._s(_vm.trans("task.archived")))]
                                  )
                                ]
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-t-10" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm.task.progress_type === "sub_task" ||
                  _vm.task.progress_type === "question" ||
                  _vm.task.sign_off_status === "requested" ||
                  _vm.task.sign_off_status === "approved"
                    ? _c(
                        "div",
                        {
                          staticClass: "progress",
                          staticStyle: { height: "20px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "progress-bar bg-success",
                              style: "width:" + _vm.progress + "%;",
                              attrs: {
                                role: "progressbar",
                                "aria-valuenow": _vm.progress,
                                "aria-valuemin": "0",
                                "aria-valuemax": "100"
                              }
                            },
                            [_vm._v(_vm._s(_vm.progress) + "% Complete")]
                          )
                        ]
                      )
                    : _c(
                        "div",
                        { staticStyle: { padding: "0px" } },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(
                              "Progress (" +
                                _vm._s(_vm.taskProgressForm.progress) +
                                "%)"
                            )
                          ]),
                          _c("br"),
                          _vm._v(" "),
                          _c("range-slider", {
                            staticClass: "slider",
                            attrs: { min: "0", max: "100", step: "1" },
                            on: { change: _vm.sliderChange },
                            model: {
                              value: _vm.taskProgressForm.progress,
                              callback: function($$v) {
                                _vm.$set(_vm.taskProgressForm, "progress", $$v)
                              },
                              expression: "taskProgressForm.progress"
                            }
                          })
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c("span", [
                    _c("small", [
                      _vm._v(
                        _vm._s(_vm.trans("task.progress_type")) +
                          ": " +
                          _vm._s(
                            _vm.trans(
                              "task." + _vm.task.progress_type + "_progress"
                            )
                          )
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-4 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("task.assigned_user")))
              ]),
              _vm._v(" "),
              _vm.task.user.length
                ? _c("h6", { staticClass: "card-subtitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("general.total_result_found", {
                          count: _vm.task.user.length
                        })
                      )
                    )
                  ])
                : _c("h6", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                  ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(_vm.task.user, function(user) {
                return _vm.task.user
                  ? _c("div", { staticClass: "d-flex flex-row m-b-20" }, [
                      _c("div", {}, [
                        _c("img", {
                          staticClass: "img-circle",
                          attrs: {
                            src: _vm.getAvatar(user),
                            alt: "user",
                            width: "70"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "p-l-20" }, [
                        _c("h4", { staticClass: "font-medium" }, [
                          _vm._v(
                            _vm._s(
                              user.profile.first_name +
                                " " +
                                user.profile.last_name
                            )
                          )
                        ]),
                        _vm._v(" "),
                        user.profile.designation
                          ? _c("h6", [
                              _vm._v(
                                _vm._s(
                                  user.profile.designation.name +
                                    " " +
                                    _vm.trans("general.in") +
                                    " " +
                                    user.profile.designation.department.name
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "time" }, [
                          _c("small", [
                            _vm._v(
                              _vm._s(_vm.trans("task.assigned_at")) +
                                " " +
                                _vm._s(
                                  _vm._f("momentDateTime")(
                                    user.pivot.created_at
                                  )
                                )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getTaskUserRating(user, _vm.task)
                            )
                          }
                        })
                      ])
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              !_vm.task.user
                ? _c("div", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("task.no_assigned_user")))
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-8 col-md-8" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "ul",
            {
              staticClass: "nav nav-tabs profile-tab",
              attrs: { role: "tablist" }
            },
            [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link active",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#detail",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showDetailTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.detail")))]
                )
              ]),
              _vm._v(" "),
              _vm.task.question_set_id
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#question",
                          role: "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showHideTabs("showQuestionTab")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.trans("task.question")))]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.progress == 100
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#sign-off",
                          role: "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showHideTabs("showTaskSignOffTab")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.trans("task.sign_off")))]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isRatingAllowed
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#rating",
                          role: "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showHideTabs("showTaskRatingTab")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.trans("task.rating")))]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#sub-task",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showSubTaskTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.sub_task")))]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#comment",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showTaskCommentTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.comment")))]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: { "data-toggle": "tab", href: "#note", role: "tab" },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showTaskNoteTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.note")))]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#attachment",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showTaskAttachmentTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.attachment")))]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              {
                staticClass: "tab-pane active",
                attrs: { id: "detail", role: "tabpanel" }
              },
              [
                _vm.tabs.showDetailTab
                  ? _c("div", { staticClass: "card-body" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.task.description) }
                      }),
                      _vm._v(" "),
                      _vm.attachments.length
                        ? _c("div", [
                            _c(
                              "ul",
                              {
                                staticClass: "m-t-10",
                                staticStyle: {
                                  "list-style": "none",
                                  padding: "0"
                                }
                              },
                              _vm._l(_vm.attachments, function(attachment) {
                                return _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/task/" +
                                          _vm.task.uuid +
                                          "/attachment/" +
                                          attachment.uuid +
                                          "/download?token=" +
                                          _vm.authToken
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-paperclip"
                                      }),
                                      _vm._v(
                                        " " + _vm._s(attachment.user_filename)
                                      )
                                    ]
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfix" })
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.task.question_set
              ? _c(
                  "div",
                  {
                    staticClass: "tab-pane",
                    attrs: { id: "question", role: "tabpanel" }
                  },
                  [
                    _vm.tabs.showQuestionTab
                      ? _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v(
                                _vm._s(_vm.trans("task.answer_to_questions"))
                              )
                            ]),
                            _vm._v(" "),
                            !_vm.is_locked
                              ? [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return _vm.submit($event)
                                        },
                                        keydown: function($event) {
                                          return _vm.questionForm.errors.clear(
                                            $event.target.name
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._l(_vm.questionForm.answers, function(
                                        answer
                                      ) {
                                        return [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-12 col-sm-4"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { attrs: { for: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            answer.question
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "radio radio-info"
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                answer.answer,
                                                              expression:
                                                                "answer.answer"
                                                            }
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            value: "yes",
                                                            id:
                                                              "answer_" +
                                                              answer.id +
                                                              "_1",
                                                            name:
                                                              "answer_" +
                                                              answer.id
                                                          },
                                                          domProps: {
                                                            checked:
                                                              answer.answer ==
                                                              "yes",
                                                            checked: _vm._q(
                                                              answer.answer,
                                                              "yes"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.questionForm.errors.clear(
                                                                "answer_" +
                                                                  answer.id
                                                              )
                                                            },
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                answer,
                                                                "answer",
                                                                "yes"
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "m-r-20",
                                                            attrs: {
                                                              for:
                                                                "answer_" +
                                                                answer.id +
                                                                "_1"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.trans(
                                                                    "list.yes"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                answer.answer,
                                                              expression:
                                                                "answer.answer"
                                                            }
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            value: "no",
                                                            id:
                                                              "answer_" +
                                                              answer.id +
                                                              "_0",
                                                            name:
                                                              "answer_" +
                                                              answer.id
                                                          },
                                                          domProps: {
                                                            checked:
                                                              answer.answer ==
                                                              "no",
                                                            checked: _vm._q(
                                                              answer.answer,
                                                              "no"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.questionForm.errors.clear(
                                                                "answer_" +
                                                                  answer.id
                                                              )
                                                            },
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                answer,
                                                                "answer",
                                                                "no"
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for:
                                                                "answer_" +
                                                                answer.id +
                                                                "_0"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.trans(
                                                                    "list.no"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("show-error", {
                                                      attrs: {
                                                        "form-name":
                                                          _vm.questionForm,
                                                        "prop-name":
                                                          "answer_" + answer.id
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-12 col-sm-8"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c("autosize-textarea", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        rows: "2",
                                                        placeholder: _vm.trans(
                                                          "task.answer_description"
                                                        ),
                                                        name: "description"
                                                      },
                                                      model: {
                                                        value:
                                                          answer.description,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            answer,
                                                            "description",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "answer.description"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-info pull-right",
                                            attrs: { type: "submit" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.trans("general.save"))
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ]
                              : _vm._l(_vm.questionForm.answers, function(
                                  answer
                                ) {
                                  return _c("div", [
                                    _c("h6", [
                                      answer.answer == "yes"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-success m-r-20"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-check-circle"
                                              })
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-times-circle m-r-20"
                                              })
                                            ]
                                          ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(answer.question) +
                                          "\n                                        "
                                      ),
                                      answer.description
                                        ? _c(
                                            "p",
                                            {
                                              staticClass: "m-r-30 m-t-10",
                                              staticStyle: { color: "#484848" }
                                            },
                                            [_vm._v(_vm._s(answer.description))]
                                          )
                                        : _vm._e()
                                    ])
                                  ])
                                })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.progress == 100
              ? _c(
                  "div",
                  {
                    staticClass: "tab-pane",
                    attrs: { id: "sign-off", role: "tabpanel" }
                  },
                  [
                    _vm.tabs.showTaskSignOffTab
                      ? _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("task-sign-off", {
                              attrs: {
                                uuid: _vm.uuid,
                                task: _vm.task,
                                users: _vm.assigned_user
                              },
                              on: { signOffStatusUpdated: _vm.getTask }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isRatingAllowed
              ? _c(
                  "div",
                  {
                    staticClass: "tab-pane",
                    attrs: { id: "rating", role: "tabpanel" }
                  },
                  [
                    _vm.tabs.showTaskRatingTab
                      ? _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("task-rating", {
                              attrs: { uuid: _vm.uuid, task: _vm.task },
                              on: { ratingComplete: _vm.getTask }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "sub-task", role: "tabpanel" }
              },
              [
                _vm.tabs.showSubTaskTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("sub-task", {
                          attrs: { uuid: _vm.uuid },
                          on: { updateProgress: _vm.getTask }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "comment", role: "tabpanel" }
              },
              [
                _vm.tabs.showTaskCommentTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-comment", { attrs: { uuid: _vm.uuid } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "note", role: "tabpanel" }
              },
              [
                _vm.tabs.showTaskNoteTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-note", { attrs: { uuid: _vm.uuid } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "attachment", role: "tabpanel" }
              },
              [
                _vm.tabs.showTaskAttachmentTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-attachment", { attrs: { uuid: _vm.uuid } })],
                      1
                    )
                  : _vm._e()
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }