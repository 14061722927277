var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("user.user_detail")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/user" } }, [
                _vm._v(_vm._s(_vm.trans("user.user")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("user.avatar")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-12 col-lg-8 col-md-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("user-sidebar", { attrs: { menu: "avatar", id: _vm.id } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-9 col-lg-9 col-md-9" },
                    [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("user.avatar")))
                      ]),
                      _vm._v(" "),
                      _c("upload-image", {
                        attrs: {
                          id: "avatar",
                          "upload-path": "/user/profile/avatar/" + _vm.id,
                          "remove-path":
                            "/user/profile/avatar/remove/" + _vm.id,
                          "image-source": _vm.avatar.user
                        },
                        on: {
                          uploaded: _vm.updateAvatar,
                          removed: _vm.updateAvatar
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("user-summary", { attrs: { user: _vm.user } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }