var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPanel
    ? _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h4", { staticClass: "card-title" }, [
            _vm._v(_vm._s(_vm.trans("task.task_copy")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.copy($event)
                    },
                    keydown: function($event) {
                      return _vm.taskCopyForm.errors.clear($event.target.name)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox checkbox-info checkbox-circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskCopyForm.assigned_user,
                                  expression: "taskCopyForm.assigned_user"
                                }
                              ],
                              attrs: {
                                id: "assigned_user",
                                type: "checkbox",
                                value: "1"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.taskCopyForm.assigned_user
                                )
                                  ? _vm._i(
                                      _vm.taskCopyForm.assigned_user,
                                      "1"
                                    ) > -1
                                  : _vm.taskCopyForm.assigned_user
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.taskCopyForm.assigned_user,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "assigned_user",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "assigned_user",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.taskCopyForm,
                                      "assigned_user",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "assigned_user" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.trans("task.copy_assigned_user"))
                              )
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox checkbox-info checkbox-circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskCopyForm.sub_task,
                                  expression: "taskCopyForm.sub_task"
                                }
                              ],
                              attrs: {
                                id: "sub_task",
                                type: "checkbox",
                                value: "1"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.taskCopyForm.sub_task
                                )
                                  ? _vm._i(_vm.taskCopyForm.sub_task, "1") > -1
                                  : _vm.taskCopyForm.sub_task
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.taskCopyForm.sub_task,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "sub_task",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "sub_task",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.taskCopyForm, "sub_task", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "sub_task" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.trans("task.copy_sub_task")) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox checkbox-info checkbox-circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskCopyForm.task_configuration,
                                  expression: "taskCopyForm.task_configuration"
                                }
                              ],
                              attrs: {
                                id: "task_configuration",
                                type: "checkbox",
                                value: "1"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.taskCopyForm.task_configuration
                                )
                                  ? _vm._i(
                                      _vm.taskCopyForm.task_configuration,
                                      "1"
                                    ) > -1
                                  : _vm.taskCopyForm.task_configuration
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.taskCopyForm.task_configuration,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "task_configuration",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "task_configuration",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.taskCopyForm,
                                      "task_configuration",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "task_configuration" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.trans("task.copy_task_configuration")
                                    )
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox checkbox-info checkbox-circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskCopyForm.attachments,
                                  expression: "taskCopyForm.attachments"
                                }
                              ],
                              attrs: {
                                id: "attachments",
                                type: "checkbox",
                                value: "1"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.taskCopyForm.attachments
                                )
                                  ? _vm._i(_vm.taskCopyForm.attachments, "1") >
                                    -1
                                  : _vm.taskCopyForm.attachments
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.taskCopyForm.attachments,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "attachments",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "attachments",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.taskCopyForm,
                                      "attachments",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "attachments" } }, [
                              _vm._v(
                                " " + _vm._s(_vm.trans("task.copy_attachments"))
                              )
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox checkbox-info checkbox-circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskCopyForm.notes,
                                  expression: "taskCopyForm.notes"
                                }
                              ],
                              attrs: {
                                id: "notes",
                                type: "checkbox",
                                value: "1"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.taskCopyForm.notes)
                                  ? _vm._i(_vm.taskCopyForm.notes, "1") > -1
                                  : _vm.taskCopyForm.notes
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.taskCopyForm.notes,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "notes",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "notes",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.taskCopyForm, "notes", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "notes" } }, [
                              _vm._v(" " + _vm._s(_vm.trans("task.copy_notes")))
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "checkbox checkbox-info checkbox-circle"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskCopyForm.zero_progress,
                                  expression: "taskCopyForm.zero_progress"
                                }
                              ],
                              attrs: {
                                id: "zero_progress",
                                type: "checkbox",
                                value: "1"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.taskCopyForm.zero_progress
                                )
                                  ? _vm._i(
                                      _vm.taskCopyForm.zero_progress,
                                      "1"
                                    ) > -1
                                  : _vm.taskCopyForm.zero_progress
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.taskCopyForm.zero_progress,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "zero_progress",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.taskCopyForm,
                                          "zero_progress",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.taskCopyForm,
                                      "zero_progress",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "zero_progress" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.trans("task.set_zero_progress"))
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info waves-effect waves-light",
                      attrs: { type: "submit" }
                    },
                    [_vm._v(_vm._s(_vm.trans("task.copy")))]
                  ),
                  _vm._v(" "),
                  _vm.showPanel
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: { click: _vm.toggle }
                        },
                        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                      )
                    : _vm._e()
                ]
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }