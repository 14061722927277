var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c(
      "div",
      {
        staticClass: "login-register",
        staticStyle: { "background-image": "url(/images/background.jpg)" }
      },
      [
        _c(
          "div",
          { staticClass: "login-box card" },
          [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("center", { staticClass: "m-t-30" }, [
                  _c("img", {
                    staticClass: "img-circle",
                    attrs: { src: _vm.getAuthUser("avatar"), width: "100" }
                  }),
                  _vm._v(" "),
                  _c("h4", { staticClass: "card-title m-t-10" }, [
                    _vm._v(_vm._s(_vm.getAuthUser("full_name")))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "form-horizontal form-material",
                    attrs: { id: "lockScreenForm" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submit($event)
                      },
                      keydown: function($event) {
                        return _vm.lockScreenForm.errors.clear(
                          $event.target.name
                        )
                      }
                    }
                  },
                  [
                    _c("h3", { staticClass: "box-title m-b-20 text-center" }, [
                      _vm._v(_vm._s(_vm.trans("auth.password")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group " },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lockScreenForm.password,
                              expression: "lockScreenForm.password"
                            }
                          ],
                          staticClass: "form-control text-center",
                          attrs: {
                            type: "password",
                            name: "password",
                            placeholder: _vm.trans("auth.password")
                          },
                          domProps: { value: _vm.lockScreenForm.password },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.lockScreenForm,
                                "password",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.lockScreenForm,
                            "prop-name": "password"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group text-center m-t-20" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(_vm._s(_vm.trans("auth.confirm")))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group m-b-0" }, [
                      _c("div", { staticClass: "col-sm-12 text-center" }, [
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.logout($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-power-off" }),
                              _vm._v(" " + _vm._s(_vm.trans("auth.logout")))
                            ]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("guest-footer")
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }