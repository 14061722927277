var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed($event)
        },
        keydown: function($event) {
          return _vm.announcementForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6" },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("announcement.title")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.announcementForm.title,
                      expression: "announcementForm.title"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    value: "",
                    name: "title",
                    placeholder: _vm.trans("announcement.title")
                  },
                  domProps: { value: _vm.announcementForm.title },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.announcementForm,
                        "title",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.announcementForm,
                    "prop-name": "title"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("announcement.start_date")))
                    ]),
                    _vm._v(" "),
                    _c("datepicker", {
                      attrs: {
                        bootstrapStyling: true,
                        placeholder: _vm.trans("announcement.start_date")
                      },
                      on: {
                        selected: function($event) {
                          return _vm.announcementForm.errors.clear("start_date")
                        }
                      },
                      model: {
                        value: _vm.announcementForm.start_date,
                        callback: function($$v) {
                          _vm.$set(_vm.announcementForm, "start_date", $$v)
                        },
                        expression: "announcementForm.start_date"
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.announcementForm,
                        "prop-name": "start_date"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("announcement.end_date")))
                    ]),
                    _vm._v(" "),
                    _c("datepicker", {
                      attrs: {
                        bootstrapStyling: true,
                        placeholder: _vm.trans("announcement.end_date")
                      },
                      on: {
                        selected: function($event) {
                          return _vm.announcementForm.errors.clear("end_date")
                        }
                      },
                      model: {
                        value: _vm.announcementForm.end_date,
                        callback: function($$v) {
                          _vm.$set(_vm.announcementForm, "end_date", $$v)
                        },
                        expression: "announcementForm.end_date"
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.announcementForm,
                        "prop-name": "end_date"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _vm.hasAdminRole()
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("switches", {
                      staticClass: "m-l-20",
                      attrs: { theme: "bootstrap", color: "success" },
                      model: {
                        value: _vm.announcementForm.is_public,
                        callback: function($$v) {
                          _vm.$set(_vm.announcementForm, "is_public", $$v)
                        },
                        expression: "announcementForm.is_public"
                      }
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.trans("announcement.is_public")) + " "
                    ),
                    _c("show-tip", {
                      attrs: {
                        module: "announcement",
                        tip: "tip_is_public",
                        type: "field"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.announcementForm.is_public
              ? [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        { attrs: { for: "" } },
                        [
                          _vm._v(
                            _vm._s(_vm.trans("announcement.restricted_to")) +
                              " "
                          ),
                          _c("show-tip", {
                            attrs: {
                              module: "announcement",
                              tip: "tip_restricted_to",
                              type: "field"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.announcementForm.restricted_to,
                              expression: "announcementForm.restricted_to"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "restricted_to",
                            placeholder: _vm.trans("general.select_one")
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.announcementForm,
                                  "restricted_to",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function($event) {
                                return _vm.announcementForm.errors.clear(
                                  "restricted_to"
                                )
                              }
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "", disabled: "" } }, [
                            _vm._v(_vm._s(_vm.trans("general.select_one")))
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "designation" } }, [
                            _vm._v(_vm._s(_vm.trans("designation.designation")))
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "location" } }, [
                            _vm._v(_vm._s(_vm.trans("location.location")))
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "user" } }, [
                            _vm._v(_vm._s(_vm.trans("user.user")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.announcementForm,
                          "prop-name": "restricted_to"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.announcementForm.restricted_to === "designation"
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("designation.designation")))
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              label: "name",
                              "track-by": "id",
                              name: "designation_id",
                              id: "designation_id",
                              options: _vm.designations,
                              placeholder: _vm.trans(
                                "designation.select_designation"
                              ),
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": true,
                              selected: _vm.selected_designations
                            },
                            on: {
                              select: _vm.onDesignationSelect,
                              remove: _vm.onDesignationRemove
                            },
                            model: {
                              value: _vm.selected_designations,
                              callback: function($$v) {
                                _vm.selected_designations = $$v
                              },
                              expression: "selected_designations"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.announcementForm,
                              "prop-name": "designation_id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.announcementForm.restricted_to === "location"
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("location.location")))
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              label: "name",
                              "track-by": "id",
                              name: "location_id",
                              id: "location_id",
                              options: _vm.locations,
                              placeholder: _vm.trans(
                                "location.select_location"
                              ),
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": true,
                              selected: _vm.selected_locations
                            },
                            on: {
                              select: _vm.onLocationSelect,
                              remove: _vm.onLocationRemove
                            },
                            model: {
                              value: _vm.selected_locations,
                              callback: function($$v) {
                                _vm.selected_locations = $$v
                              },
                              expression: "selected_locations"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.announcementForm,
                              "prop-name": "location_id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.announcementForm.restricted_to === "user"
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("user.user")))
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              label: "name",
                              "track-by": "id",
                              name: "user_id",
                              id: "user_id",
                              options: _vm.users,
                              placeholder: _vm.trans("user.select_user"),
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": true,
                              selected: _vm.selected_users
                            },
                            on: {
                              select: _vm.onUserSelect,
                              remove: _vm.onUserRemove
                            },
                            model: {
                              value: _vm.selected_users,
                              callback: function($$v) {
                                _vm.selected_users = $$v
                              },
                              expression: "selected_users"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.announcementForm,
                              "prop-name": "user_id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("file-upload-input", {
                  attrs: {
                    "button-text": _vm.trans("general.upload_document"),
                    token: _vm.announcementForm.upload_token,
                    module: "announcement",
                    "clear-file": _vm.clearAnnouncementAttachment,
                    "module-id": _vm.id || ""
                  }
                })
              ],
              1
            )
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-md-6" },
          [
            _c("html-editor", {
              attrs: {
                name: "description",
                model: _vm.announcementForm.description,
                isUpdate: _vm.id ? true : false
              },
              on: {
                "update:model": function($event) {
                  return _vm.$set(_vm.announcementForm, "description", $event)
                },
                clearErrors: function($event) {
                  return _vm.announcementForm.errors.clear("description")
                }
              }
            }),
            _vm._v(" "),
            _c("show-error", {
              attrs: {
                "form-name": _vm.announcementForm,
                "prop-name": "description"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass: "btn btn-danger waves-effect waves-light",
          attrs: { to: "/announcement" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }