var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("general.report")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("task.task_summary")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("task.task_summary")))
            ]),
            _vm._v(" "),
            _vm.reports.length
              ? _c("h6", { staticClass: "card-subtitle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.trans("general.total_result_found", {
                        count: _vm.reports.length
                      })
                    )
                  )
                ])
              : _c("h6", { staticClass: "card-subtitle" }, [
                  _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive" }, [
              _vm.reports.length
                ? _c("table", { staticClass: "table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.trans("user.name")))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.trans("task.owned")))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.trans("task.assigned")))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.trans("task.pending")))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.trans("task.completed")))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.trans("task.overdue")))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.trans("task.rating")))])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.reports, function(report) {
                        return _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.getUserNameWithDesignationAndDepartment(
                                  report.user
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(report.owned_tasks)
                            }
                          }),
                          _vm._v(" "),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(report.assigned_tasks)
                            }
                          }),
                          _vm._v(" "),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(report.pending_tasks)
                            }
                          }),
                          _vm._v(" "),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(report.completed_tasks)
                            }
                          }),
                          _vm._v(" "),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(report.overdue_tasks)
                            }
                          }),
                          _vm._v(" "),
                          _c("td", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.generateRatingStar(report.rating)
                              )
                            }
                          })
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }