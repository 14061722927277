var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("general.home")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("general.home")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6 col-md-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("task.dashboard_task_count", {
                    type: _vm.trans("task.total")
                  })
                ) + " 111111"
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c("h2", { staticClass: "font-light m-b-0" }, [
                _c("i", {
                  staticClass: "fas fa-tasks fa-lg pull-right text-info"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "pull-left" }, [
                  _vm._v(_vm._s(_vm.task_stats.total))
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-md-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("task.dashboard_task_count", {
                    type: _vm.trans("task.owned")
                  })
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c("h2", { staticClass: "font-light m-b-0" }, [
                _c("i", {
                  staticClass: "fas fa-user fa-lg pull-right text-success"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "pull-left" }, [
                  _vm._v(_vm._s(_vm.task_stats.owned))
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-md-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.trans("task.dashboard_task_count", {
                      type: _vm.trans("task.unassigned")
                    })
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c("h2", { staticClass: "font-light m-b-0" }, [
                _c("i", {
                  staticClass: "fas fa-user-times fa-lg pull-right text-warning"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "pull-left" }, [
                  _vm._v(_vm._s(_vm.task_stats.unassigned))
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-md-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("task.dashboard_task_count", {
                    type: _vm.trans("task.pending")
                  })
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c("h2", { staticClass: "font-light m-b-0" }, [
                _c("i", {
                  staticClass: "fas fa-spinner fa-lg pull-right text-danger"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "pull-left" }, [
                  _vm._v(_vm._s(_vm.task_stats.pending))
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-md-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("task.dashboard_task_count", {
                    type: _vm.trans("task.overdue")
                  })
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c("h2", { staticClass: "font-light m-b-0" }, [
                _c("i", {
                  staticClass: "fas fa-fire fa-lg pull-right text-danger"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "pull-left" }, [
                  _vm._v(_vm._s(_vm.task_stats.overdue))
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-md-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("task.dashboard_task_count", {
                    type: _vm.trans("task.completed")
                  })
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c("h2", { staticClass: "font-light m-b-0" }, [
                _c("i", {
                  staticClass:
                    "fas fa-battery-full fa-lg pull-right text-success"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "pull-left" }, [
                  _vm._v(_vm._s(_vm.task_stats.completed))
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("task.task_category")))
              ]),
              _vm._v(" "),
              _c("doughnut-graph", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.graph.task_category.labels.length,
                    expression: "graph.task_category.labels.length"
                  }
                ],
                attrs: { graph: _vm.graph.task_category }
              }),
              _vm._v(" "),
              !_vm.graph.task_category.labels.length
                ? _c("h6", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("task.task_priority")))
              ]),
              _vm._v(" "),
              _c("doughnut-graph", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.graph.task_priority.labels.length,
                    expression: "graph.task_priority.labels.length"
                  }
                ],
                attrs: { graph: _vm.graph.task_priority }
              }),
              _vm._v(" "),
              !_vm.graph.task_priority.labels.length
                ? _c("h6", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("task.status")))
              ]),
              _vm._v(" "),
              _c("doughnut-graph", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.graph.task_status.labels.length,
                    expression: "graph.task_status.labels.length"
                  }
                ],
                attrs: { graph: _vm.graph.task_status }
              }),
              _vm._v(" "),
              !_vm.graph.task_status.labels.length
                ? _c("h6", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "ul",
            {
              staticClass: "nav nav-tabs profile-tab",
              attrs: { role: "tablist" }
            },
            [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link active",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#starred",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showStarredTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.starred")))]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#pending",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showPendingTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.pending")))]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#overdue",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showOverdueTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.overdue")))]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#owned",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showOwnedTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.owned")))]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-toggle": "tab",
                      href: "#unassigned",
                      role: "tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showUnassignedTab")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.trans("task.unassigned")))]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              {
                staticClass: "tab-pane active",
                attrs: { id: "starred", role: "tabpanel" }
              },
              [
                _vm.tabs.showStarredTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-list", { attrs: { option: "starred" } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "pending", role: "tabpanel" }
              },
              [
                _vm.tabs.showPendingTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-list", { attrs: { option: "pending" } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "overdue", role: "tabpanel" }
              },
              [
                _vm.tabs.showOverdueTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-list", { attrs: { option: "overdue" } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "owned", role: "tabpanel" }
              },
              [
                _vm.tabs.showOwnedTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-list", { attrs: { option: "owned" } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "unassigned", role: "tabpanel" }
              },
              [
                _vm.tabs.showUnassignedTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-list", { attrs: { option: "unassigned" } })],
                      1
                    )
                  : _vm._e()
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.getConfig("todo")
        ? _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "message-scroll" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-success btn-xs pull-right",
                        attrs: { to: "/todo" }
                      },
                      [_vm._v(_vm._s(_vm.trans("general.view_all")))]
                    ),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("todo.pending_todo")))
                    ]),
                    _vm._v(" "),
                    !_vm.pending_todos.length
                      ? _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pending_todos.length
                      ? _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("todo.title")))
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "table-option" }, [
                                  _vm._v(_vm._s(_vm.trans("todo.due_date")))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.pending_todos, function(todo) {
                                return _c("tr", [
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-xs btn-danger m-r-5",
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleTodo(todo)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fas fa-times" })]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(todo.title) +
                                        "\n                                        "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "table-option" }, [
                                    _vm._v(_vm._s(_vm._f("moment")(todo.date)))
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("todo.recently_completed_todo")))
                    ]),
                    _vm._v(" "),
                    !_vm.completed_todos.length
                      ? _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.completed_todos.length
                      ? _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("todo.title")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("todo.due_date")))
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "table-option" }, [
                                  _vm._v(_vm._s(_vm.trans("todo.completed_at")))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.completed_todos, function(todo) {
                                return _c("tr", [
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-xs btn-success m-r-5",
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleTodo(todo)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fas fa-check" })]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "text-decoration": "line-through"
                                        }
                                      },
                                      [_vm._v(_vm._s(todo.title))]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm._f("moment")(todo.date)))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "table-option" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("momentDateTime")(
                                          todo.completed_at
                                        )
                                      )
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "message-scroll" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("task.user_rating_top_chart")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "comment-widgets" },
                [
                  _vm._l(_vm.top_charts, function(top_chart) {
                    return _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row comment-row",
                        staticStyle: { padding: "0 15px" }
                      },
                      [
                        _c("div", { staticClass: "p-2" }, [
                          _c("span", { staticClass: "round" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.getAvatar(top_chart.user),
                                alt: "user",
                                width: "50"
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "comment-text w-100" }, [
                          _c("h5", [
                            _vm._v(
                              _vm._s(
                                top_chart.user.profile.first_name +
                                  " " +
                                  top_chart.user.profile.last_name +
                                  " (" +
                                  top_chart.user.profile.designation.name +
                                  " " +
                                  top_chart.user.profile.designation.department
                                    .name +
                                  ")"
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "m-b-5",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.generateRatingStar(top_chart.rating)
                              )
                            }
                          }),
                          _vm._v(" ("),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                top_chart.task_count +
                                  " " +
                                  _vm.trans("task.task")
                              )
                            )
                          ]),
                          _vm._v(")\n                                ")
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  !_vm.top_charts.length
                    ? _c("h6", { staticClass: "card-subtitle" }, [
                        _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                      ])
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.getConfig("announcement")
        ? _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("div", { staticClass: "message-scroll" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("announcement.announcement")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "comment-widgets" },
                      _vm._l(_vm.announcements, function(announcement) {
                        return _c(
                          "div",
                          { staticClass: "d-flex flex-row comment-row" },
                          [
                            _c("div", { staticClass: "p-2" }, [
                              _c("span", { staticClass: "round" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.getAvatar(announcement.user_added),
                                    alt: "user",
                                    width: "50"
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "comment-text w-100",
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  "data-toggle": "modal",
                                  "data-target": ".announcement-detail"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.announcement_id = announcement.id
                                  }
                                }
                              },
                              [
                                _c("h5", [
                                  _vm._v(
                                    _vm._s(
                                      announcement.user_added.profile
                                        .first_name +
                                        " " +
                                        announcement.user_added.profile
                                          .last_name +
                                        " (" +
                                        announcement.user_added.profile
                                          .designation.name +
                                        " " +
                                        announcement.user_added.profile
                                          .designation.department.name +
                                        ")"
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "m-b-5" }, [
                                  _vm._v(_vm._s(announcement.title))
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "comment-footer" }, [
                                  _c(
                                    "span",
                                    { staticClass: "text-muted pull-right" },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.trans("announcement.posted_on")
                                          ) +
                                            ": " +
                                            _vm._s(
                                              _vm._f("momentDateTime")(
                                                announcement.created_at
                                              )
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    !_vm.announcements.length
                      ? _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("announcement-detail", {
                    attrs: { id: _vm.announcement_id }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.getConfig("activity_log")
        ? _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "message-scroll" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(_vm.trans("activity.activity_log")))
                  ]),
                  _vm._v(" "),
                  !_vm.activity_logs.length
                    ? _c("h6", { staticClass: "card-subtitle" }, [
                        _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activity_logs.length
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _vm.hasAdminRole()
                                ? _c("th", [
                                    _vm._v(_vm._s(_vm.trans("user.user")))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("activity.activity")))
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("activity.date_time")))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.activity_logs, function(activity_log) {
                              return _c("tr", [
                                _vm.hasAdminRole()
                                  ? _c("td", {
                                      domProps: {
                                        textContent: _vm._s(
                                          activity_log.user.profile.first_name +
                                            " " +
                                            activity_log.user.profile.last_name
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans(
                                        "activity." + activity_log.activity,
                                        {
                                          activity: _vm.trans(
                                            "activity." + activity_log.module
                                          )
                                        }
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("momentDateTime")(
                                        activity_log.created_at
                                      )
                                    )
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }