var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("permission.permission")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", { attrs: { menu: "permission" } }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-10 col-lg-10 col-md-10" },
                  [
                    _c("show-tip", {
                      attrs: { module: "permission", tip: "tip_permission" }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-4 col-md-4" },
                        [
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(
                              _vm._s(_vm.trans("permission.add_new_permission"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("permission-form", {
                            on: { completed: _vm.getPermissions }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-8 col-md-8" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm btn-info pull-right",
                              attrs: { to: "/configuration/permission/assign" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("permission.assign_permission")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(
                              _vm._s(_vm.trans("permission.permission_list"))
                            )
                          ]),
                          _vm._v(" "),
                          _vm.permissions
                            ? _c("h6", { staticClass: "card-subtitle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("general.total_result_found", {
                                      count: _vm.permissions.total
                                    })
                                  )
                                )
                              ])
                            : _c("h6", { staticClass: "card-subtitle" }, [
                                _vm._v(
                                  _vm._s(_vm.trans("general.no_result_found"))
                                )
                              ]),
                          _vm._v(" "),
                          _vm.permissions.total
                            ? _c("div", { staticClass: "table-responsive" }, [
                                _c("table", { staticClass: "table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.trans("permission.name"))
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        { staticClass: "table-option" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.trans("general.action"))
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.permissions.data, function(
                                      permission
                                    ) {
                                      return _c("tr", [
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.toWord(permission.name)
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "table-option" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "btn-group" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "confirm",
                                                        rawName: "v-confirm",
                                                        value: {
                                                          ok: _vm.confirmDelete(
                                                            permission
                                                          )
                                                        },
                                                        expression:
                                                          "{ok: confirmDelete(permission)}"
                                                      },
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: _vm.trans(
                                                          "permission.delete_permission"
                                                        ),
                                                        expression:
                                                          "trans('permission.delete_permission')"
                                                      }
                                                    ],
                                                    key: permission.id,
                                                    staticClass:
                                                      "btn btn-danger btn-sm"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-trash"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.permissions.total
                            ? _c("module-info", {
                                attrs: {
                                  module: "permission",
                                  title: "module_info_title",
                                  description: "module_info_description",
                                  icon: "key"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("pagination-record", {
                            attrs: {
                              "page-length":
                                _vm.filterPermissionForm.page_length,
                              records: _vm.permissions
                            },
                            on: {
                              "update:pageLength": function($event) {
                                return _vm.$set(
                                  _vm.filterPermissionForm,
                                  "page_length",
                                  $event
                                )
                              },
                              "update:page-length": function($event) {
                                return _vm.$set(
                                  _vm.filterPermissionForm,
                                  "page_length",
                                  $event
                                )
                              },
                              updateRecords: _vm.getPermissions
                            },
                            nativeOn: {
                              change: function($event) {
                                return _vm.getPermissions($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }