var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade task-attachment-form",
      staticStyle: { display: "none" },
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "taskAttachmentForm",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h4",
              {
                staticClass: "modal-title",
                attrs: { id: "taskAttachmentForm" }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.local_id
                      ? _vm.trans("task.edit_task_attachment")
                      : _vm.trans("task.add_new_attachment")
                  )
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-hidden": "true"
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.proceed($event)
                  },
                  keydown: function($event) {
                    return _vm.taskAttachmentForm.errors.clear(
                      $event.target.name
                    )
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("task.task_attachment_title")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskAttachmentForm.title,
                          expression: "taskAttachmentForm.title"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        value: "",
                        name: "title",
                        placeholder: _vm.trans("task.task_attachment_title")
                      },
                      domProps: { value: _vm.taskAttachmentForm.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.taskAttachmentForm,
                            "title",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.taskAttachmentForm,
                        "prop-name": "title"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("html-editor", {
                      attrs: {
                        name: "description",
                        model: _vm.taskAttachmentForm.description,
                        isUpdate: _vm.updateEditorContent
                      },
                      on: {
                        "update:model": function($event) {
                          return _vm.$set(
                            _vm.taskAttachmentForm,
                            "description",
                            $event
                          )
                        },
                        clearErrors: function($event) {
                          return _vm.taskAttachmentForm.errors.clear(
                            "description"
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.taskAttachmentForm,
                        "prop-name": "description"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("file-upload-input", {
                      attrs: {
                        "button-text": _vm.trans("general.upload_document"),
                        token: _vm.taskAttachmentForm.upload_token,
                        module: "task_attachment",
                        "clear-file": _vm.clearTaskAttachmentAttachment,
                        "module-id": _vm.module_id
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info waves-effect waves-light",
                    attrs: { type: "submit" }
                  },
                  [
                    _vm.local_id
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.trans("general.update")))
                        ])
                      : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger waves-effect waves-light",
                    attrs: { type: "button" },
                    on: { click: _vm.closeModal }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }