var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed($event)
        },
        keydown: function($event) {
          return _vm.designationForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("designation.name")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.designationForm.name,
                expression: "designationForm.name"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              name: "name",
              placeholder: _vm.trans("designation.name")
            },
            domProps: { value: _vm.designationForm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.designationForm, "name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.designationForm, "prop-name": "name" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("department.department")))
          ]),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              label: "name",
              name: "department_id",
              id: "department_id",
              options: _vm.departments,
              placeholder: _vm.trans("department.select_department")
            },
            on: {
              select: _vm.onDepartmentSelect,
              close: function($event) {
                return _vm.designationForm.errors.clear("department_id")
              },
              remove: function($event) {
                _vm.designationForm.department_id = ""
              }
            },
            model: {
              value: _vm.selected_department,
              callback: function($$v) {
                _vm.selected_department = $$v
              },
              expression: "selected_department"
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: {
              "form-name": _vm.designationForm,
              "prop-name": "department_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "label",
            { attrs: { for: "" } },
            [
              _vm._v(_vm._s(_vm.trans("designation.top_designation")) + " "),
              _c("show-tip", {
                attrs: {
                  module: "designation",
                  tip: "tip_top_designation",
                  type: "field"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              label: "name",
              name: "top_designation_id",
              id: "top_designation_id",
              options: _vm.top_designations,
              placeholder: _vm.trans("designation.select_top_designation")
            },
            on: {
              select: _vm.onTopDesignationSelect,
              close: function($event) {
                return _vm.designationForm.errors.clear("top_designation_id")
              },
              remove: function($event) {
                _vm.designationForm.top_designation_id = ""
              }
            },
            model: {
              value: _vm.selected_top_designation,
              callback: function($$v) {
                _vm.selected_top_designation = $$v
              },
              expression: "selected_top_designation"
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: {
              "form-name": _vm.designationForm,
              "prop-name": "top_designation_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("switches", {
            staticClass: "m-l-20",
            attrs: { theme: "bootstrap", color: "success" },
            model: {
              value: _vm.designationForm.is_default,
              callback: function($$v) {
                _vm.$set(_vm.designationForm, "is_default", $$v)
              },
              expression: "designationForm.is_default"
            }
          }),
          _vm._v(" " + _vm._s(_vm.trans("designation.default")) + " "),
          _c("show-tip", {
            attrs: {
              module: "designation",
              tip: "tip_default_designation",
              type: "field"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("designation.description")))
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.designationForm.description,
                expression: "designationForm.description"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              rows: "2",
              name: "description",
              placeholder: _vm.trans("designation.description")
            },
            domProps: { value: _vm.designationForm.description },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.designationForm,
                  "description",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: {
              "form-name": _vm.designationForm,
              "prop-name": "description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass: "btn btn-danger waves-effect waves-light",
          attrs: { to: "/designation" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }