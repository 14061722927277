var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed($event)
        },
        keydown: function($event) {
          return _vm.ipFilterForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("ip_filter.start_ip")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.ipFilterForm.start_ip,
                expression: "ipFilterForm.start_ip"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              name: "start_ip",
              placeholder: _vm.trans("ip_filter.start_ip")
            },
            domProps: { value: _vm.ipFilterForm.start_ip },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.ipFilterForm, "start_ip", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.ipFilterForm, "prop-name": "start_ip" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("ip_filter.end_ip")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.ipFilterForm.end_ip,
                expression: "ipFilterForm.end_ip"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              name: "end_ip",
              placeholder: _vm.trans("ip_filter.end_ip")
            },
            domProps: { value: _vm.ipFilterForm.end_ip },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.ipFilterForm, "end_ip", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.ipFilterForm, "prop-name": "end_ip" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("ip_filter.description")))
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.ipFilterForm.description,
                expression: "ipFilterForm.description"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              rows: "2",
              name: "description",
              placeholder: _vm.trans("ip_filter.description")
            },
            domProps: { value: _vm.ipFilterForm.description },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.ipFilterForm, "description", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.ipFilterForm, "prop-name": "description" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light pull-right",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right m-r-10",
          attrs: { to: "/configuration/ip-filter" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }