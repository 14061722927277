var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("permission.assign_permission")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/permission" } },
                [_vm._v(_vm._s(_vm.trans("permission.permission")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("permission.assign_permission")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-sm btn-info waves-effect waves-light pull-right",
                  attrs: { type: "button" },
                  on: { click: _vm.savePermission }
                },
                [_vm._v(_vm._s(_vm.trans("general.save")))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-sm btn-danger pull-right m-r-10",
                  attrs: { to: "/configuration/permission" }
                },
                [_vm._v(_vm._s(_vm.trans("general.back")))]
              ),
              _vm._v(" "),
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("permission.assign_permission")))
              ]),
              _vm._v(" "),
              _c("show-tip", {
                attrs: { module: "permission", tip: "tip_assign_permission" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive m-b-20" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c("th", [
                          _vm._v(_vm._s(_vm.trans("permission.permission")))
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.roles, function(role) {
                          return _c("th", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(role.name))
                          ])
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.permissions, function(permission) {
                      return _c(
                        "tr",
                        [
                          _c("td", [
                            _vm._v(_vm._s(_vm.toWord(permission.name)))
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.roles, function(role) {
                            return _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value:
                                      _vm.assignPermissionForm.data[role.id][
                                        permission.id
                                      ],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.assignPermissionForm.data[role.id],
                                        permission.id,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "assignPermissionForm.data[role.id][permission.id]"
                                  }
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-sm btn-info waves-effect waves-light pull-right",
                  attrs: { type: "button" },
                  on: { click: _vm.savePermission }
                },
                [_vm._v(_vm._s(_vm.trans("general.save")))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-sm btn-danger pull-right m-r-10",
                  attrs: { to: "/configuration/permission" }
                },
                [_vm._v(_vm._s(_vm.trans("general.back")))]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }