var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "alert alert-info" }, [
        _c("i", { staticClass: "fas fa-exclamation-triangle" }),
        _vm._v(" " + _vm._s(_vm.trans("task.tip_task_comment")))
      ]),
      _vm._v(" "),
      _c("task-comment-form", {
        attrs: { uuid: _vm.uuid },
        on: { completed: _vm.getComments }
      }),
      _vm._v(" "),
      _vm.task_comments.length
        ? _c(
            "div",
            { staticClass: "comment-widgets comment-scroll" },
            _vm._l(_vm.task_comments, function(task_comment) {
              return _c("div", { staticClass: "d-flex flex-row comment-row" }, [
                _c("div", { staticClass: "p-2" }, [
                  _c("span", { staticClass: "round" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.getAvatar(task_comment.user),
                        alt: "user",
                        width: "50"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "comment-text w-100" },
                  [
                    task_comment.user_id === _vm.getAuthUser("id")
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "confirm",
                                rawName: "v-confirm",
                                value: { ok: _vm.confirmDelete(task_comment) },
                                expression: "{ok: confirmDelete(task_comment)}"
                              },
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.trans("task.delete_comment"),
                                expression: "trans('task.delete_comment')"
                              }
                            ],
                            key: task_comment.id,
                            staticClass: "pull-right",
                            staticStyle: {
                              cursor: "pointer",
                              "margin-left": "15px"
                            }
                          },
                          [_c("i", { staticClass: "fas fa-trash" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "text-muted pull-right",
                        staticStyle: { "font-size": "12px" }
                      },
                      [
                        _c("i", { staticClass: "fas fa-clock" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("momentFromNow")(task_comment.created_at)
                            )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("h5", [
                      _vm._v(
                        _vm._s(
                          task_comment.user.profile.first_name +
                            " " +
                            task_comment.user.profile.last_name
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "m-b-5",
                      domProps: { innerHTML: _vm._s(task_comment.comment) }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-left": "25px",
                          "margin-bottom": "-30px"
                        }
                      },
                      [
                        _c("task-comment-form", {
                          attrs: {
                            uuid: _vm.uuid,
                            id: task_comment.id,
                            type: "reply"
                          },
                          on: { completed: _vm.getComments }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.orderedReplies(task_comment.reply), function(
                      reply
                    ) {
                      return task_comment.reply
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex flex-row comment-row",
                              staticStyle: {
                                "margin-bottom": "-40px",
                                "padding-right": "0"
                              }
                            },
                            [
                              _c("div", { staticClass: "p-2" }, [
                                _c("span", { staticClass: "round" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getAvatar(reply.user),
                                      alt: "user",
                                      width: "50"
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "comment-text w-100" }, [
                                task_comment.user_id === _vm.getAuthUser("id")
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDelete(reply)
                                            },
                                            expression:
                                              "{ok: confirmDelete(reply)}"
                                          },
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "task.delete_comment"
                                            ),
                                            expression:
                                              "trans('task.delete_comment')"
                                          }
                                        ],
                                        key: reply.id,
                                        staticClass: "pull-right",
                                        staticStyle: {
                                          cursor: "pointer",
                                          "margin-left": "15px"
                                        }
                                      },
                                      [_c("i", { staticClass: "fas fa-trash" })]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-muted pull-right",
                                    staticStyle: { "font-size": "12px" }
                                  },
                                  [
                                    _c("i", { staticClass: "far fa-clock" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("momentFromNow")(
                                            reply.created_at
                                          )
                                        )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("h5", [
                                  _vm._v(
                                    _vm._s(
                                      reply.user.profile.first_name +
                                        " " +
                                        reply.user.profile.last_name
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", {
                                  staticClass: "m-b-5",
                                  domProps: { innerHTML: _vm._s(reply.comment) }
                                })
                              ])
                            ]
                          )
                        : _vm._e()
                    })
                  ],
                  2
                )
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.task_comments.length
        ? _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(_vm._s(_vm.trans("general.no_result_found")))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }