var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "carousel slide",
      attrs: { id: "installationProgress", "data-ride": "carousel" }
    },
    [
      _c(
        "div",
        { staticClass: "carousel-inner", attrs: { role: "listbox" } },
        _vm._l(_vm.quotes, function(quote, index) {
          return _c(
            "div",
            { class: ["carousel-item", index == 0 ? "active" : ""] },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "40px 0",
                    padding: "0 50px",
                    "font-style": "italic"
                  }
                },
                [
                  _c("h1", { staticClass: "text-center" }, [
                    _vm._v('"' + _vm._s(quote.quote) + '"')
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(quote.author))
                  ])
                ]
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "a",
          {
            staticClass: "pull-right text-success",
            staticStyle: { "text-decoration": "none", color: "inherit" },
            attrs: {
              href: "#installationProgress",
              role: "button",
              "data-slide": "prev"
            }
          },
          [_c("i", { staticClass: "fas fa-arrow-circle-left fa-2x" })]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "a",
          {
            staticClass: "text-success",
            staticStyle: { "text-decoration": "none", color: "inherit" },
            attrs: {
              href: "#installationProgress",
              role: "button",
              "data-slide": "next"
            }
          },
          [_c("i", { staticClass: "fas fa-arrow-circle-right fa-2x" })]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }