var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("general.menu")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", { attrs: { menu: "menu" } }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-10 col-lg-10 col-md-10" },
                  [
                    _c("show-tip", {
                      attrs: { module: "configuration", tip: "tip_menu" }
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("general.menu")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submit($event)
                          },
                          keydown: function($event) {
                            return _vm.configForm.errors.clear(
                              $event.target.name
                            )
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _vm._m(0),
                            _vm._v(" "),
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("user.user")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.show_user_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_user_menu",
                                            $$v
                                          )
                                        },
                                        expression: "configForm.show_user_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("department.department")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm.show_department_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_department_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_department_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans(
                                          "designation.designation"
                                        )
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm.show_designation_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_designation_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_designation_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("location.location")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm.show_location_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_location_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_location_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans(
                                          "announcement.announcement"
                                        )
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm.show_announcement_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_announcement_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_announcement_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("task.task")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.show_task_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_task_menu",
                                            $$v
                                          )
                                        },
                                        expression: "configForm.show_task_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("todo.todo")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.show_todo_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_todo_menu",
                                            $$v
                                          )
                                        },
                                        expression: "configForm.show_todo_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("message.message")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.show_message_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_message_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_message_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans(
                                          "configuration.configuration"
                                        )
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm
                                            .show_configuration_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_configuration_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_configuration_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("backup.backup")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.show_backup_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_backup_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_backup_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans(
                                          "template.email_template"
                                        )
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm
                                            .show_email_template_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_email_template_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_email_template_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("mail.email_log")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm.show_email_log_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_email_log_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_email_log_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("activity.activity_log")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm.show_activity_log_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_activity_log_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_activity_log_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("general.about")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.show_about_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_about_menu",
                                            $$v
                                          )
                                        },
                                        expression: "configForm.show_about_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.show_menu", {
                                        menu: _vm.trans("general.support")
                                      })
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.show_support_menu,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "show_support_menu",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.show_support_menu"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-info waves-effect waves-light pull-right m-t-10",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(_vm._s(_vm.trans("general.save")))]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [_c("tr", [_c("th"), _vm._v(" "), _c("th")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }