import { render, staticRenderFns } from "./task-note-form.vue?vue&type=template&id=81f838dc&"
import script from "./task-note-form.vue?vue&type=script&lang=js&"
export * from "./task-note-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("c:\\xampp\\htdocs\\app.poojalab.com\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('81f838dc')) {
      api.createRecord('81f838dc', component.options)
    } else {
      api.reload('81f838dc', component.options)
    }
    module.hot.accept("./task-note-form.vue?vue&type=template&id=81f838dc&", function () {
      api.rerender('81f838dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/task/task-note-form.vue"
export default component.exports