var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("general.update")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("general.update")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("general.update")))
            ]),
            _vm._v(" "),
            _vm.product.current_version == _vm.product.latest_version &&
            _vm.product.name
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("No update available! Please check later.")
                ])
              : _c(
                  "div",
                  [
                    !_vm.is_processing
                      ? [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.update_tips) }
                          }),
                          _vm._v(" "),
                          _vm.product.name
                            ? _c("div", { staticClass: "table-responsive" }, [
                                _c("table", { staticClass: "table" }, [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v("Version Available for Upgrade")
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.product.next_release_version
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v("Date of Release")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              _vm.product.next_release_date
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v("Update Size")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getFileSize(
                                              _vm.product.next_release_size
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", {
                                        attrs: { colspan: "2" },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.product.next_release_change_log
                                          )
                                        }
                                      })
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                !_vm.is_downloaded
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDownload(0)
                                            },
                                            expression:
                                              "{ok: confirmDownload(0)}"
                                          }
                                        ],
                                        key: "download",
                                        staticClass: "btn btn-info",
                                        attrs: { type: "button" }
                                      },
                                      [_vm._v("Download")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.is_downloaded
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: { ok: _vm.confirmUpdate() },
                                            expression: "{ok: confirmUpdate()}"
                                          }
                                        ],
                                        key: "direct-update",
                                        staticClass: "btn btn-info",
                                        attrs: { type: "button" }
                                      },
                                      [_vm._v("Update")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.is_downloaded
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDownload(1)
                                            },
                                            expression:
                                              "{ok: confirmDownload(1)}"
                                          }
                                        ],
                                        key: "download-update",
                                        staticClass: "btn btn-success",
                                        attrs: { type: "button" }
                                      },
                                      [_vm._v("Download & Update")]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      : [
                          _c("p", { staticClass: "text-center" }, [
                            _vm._v(
                              "Don't perform any action till we are performing update!"
                            )
                          ]),
                          _vm._v(" "),
                          _vm.is_downloading
                            ? _c("p", { staticClass: "text-center" }, [
                                _vm._v(
                                  "Update Size (" +
                                    _vm._s(
                                      _vm.getFileSize(
                                        _vm.product.next_release_size
                                      )
                                    ) +
                                    ") - Downloading....."
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.is_updating
                            ? _c("p", { staticClass: "text-center" }, [
                                _vm._v("Updating.....")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.is_downloaded
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "confirm",
                                      rawName: "v-confirm",
                                      value: { ok: _vm.confirmUpdate() },
                                      expression: "{ok: confirmUpdate()}"
                                    }
                                  ],
                                  key: "update",
                                  staticClass: "btn btn-info",
                                  attrs: { type: "button" }
                                },
                                [_vm._v("Update")]
                              )
                            : _vm._e()
                        ]
                  ],
                  2
                )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("general.product_information")))
              ]),
              _vm._v(" "),
              _c("product", { attrs: { product: _vm.product, update: "1" } })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }