var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("task.task")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("task.task")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", { attrs: { menu: "task" } }),
                _vm._v(" "),
                _c("div", { staticClass: "col-10 col-lg-10 col-md-10" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-4 col-md-4" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(
                            _vm._s(_vm.trans("task.add_new_task_category"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("task-category-form", {
                          on: { completed: _vm.getTaskCategories }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-8 col-md-8" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("task.task_category_list")))
                        ]),
                        _vm._v(" "),
                        _vm.task_categories
                          ? _c("h6", { staticClass: "card-subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("general.total_result_found", {
                                    count: _vm.task_categories.total
                                  })
                                )
                              )
                            ])
                          : _c("h6", { staticClass: "card-subtitle" }, [
                              _vm._v(
                                _vm._s(_vm.trans("general.no_result_found"))
                              )
                            ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-responsive" }, [
                          _vm.task_categories.total
                            ? _c("table", { staticClass: "table" }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("task.task_category_name")
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "task.task_category_description"
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "table-option" }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("general.action"))
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.task_categories.data, function(
                                    task_category
                                  ) {
                                    return _c("tr", [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            task_category.name
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            task_category.description
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "task.edit_task_category"
                                                      ),
                                                      expression:
                                                        "trans('task.edit_task_category')"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn btn-info btn-sm",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.editTaskCategory(
                                                        task_category
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-pencil-alt"
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "confirm",
                                                      rawName: "v-confirm",
                                                      value: {
                                                        ok: _vm.confirmTaskCategoryDelete(
                                                          task_category
                                                        )
                                                      },
                                                      expression:
                                                        "{ok: confirmTaskCategoryDelete(task_category)}"
                                                    },
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "task.delete_task_category"
                                                      ),
                                                      expression:
                                                        "trans('task.delete_task_category')"
                                                    }
                                                  ],
                                                  key:
                                                    "category_" +
                                                    task_category.id,
                                                  staticClass:
                                                    "btn btn-danger btn-sm"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-trash"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("pagination-record", {
                          attrs: {
                            "page-length":
                              _vm.filterTaskCategoryForm.page_length,
                            records: _vm.task_categories
                          },
                          on: {
                            "update:pageLength": function($event) {
                              return _vm.$set(
                                _vm.filterTaskCategoryForm,
                                "page_length",
                                $event
                              )
                            },
                            "update:page-length": function($event) {
                              return _vm.$set(
                                _vm.filterTaskCategoryForm,
                                "page_length",
                                $event
                              )
                            },
                            updateRecords: _vm.getTaskCategories
                          },
                          nativeOn: {
                            change: function($event) {
                              return _vm.getTaskCategories($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-t-20" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-4 col-md-4" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(
                            _vm._s(_vm.trans("task.add_new_task_priority"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("task-priority-form", {
                          on: { completed: _vm.getTaskPriorities }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-8 col-md-8" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("task.task_priority_list")))
                        ]),
                        _vm._v(" "),
                        _vm.task_priorities
                          ? _c("h6", { staticClass: "card-subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("general.total_result_found", {
                                    count: _vm.task_priorities.total
                                  })
                                )
                              )
                            ])
                          : _c("h6", { staticClass: "card-subtitle" }, [
                              _vm._v(
                                _vm._s(_vm.trans("general.no_result_found"))
                              )
                            ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-responsive" }, [
                          _vm.task_priorities.total
                            ? _c("table", { staticClass: "table" }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("task.task_priority_name")
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "task.task_priority_description"
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "table-option" }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("general.action"))
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.task_priorities.data, function(
                                    task_priority
                                  ) {
                                    return _c("tr", [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            task_priority.name
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            task_priority.description
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "task.edit_task_priority"
                                                      ),
                                                      expression:
                                                        "trans('task.edit_task_priority')"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn btn-info btn-sm",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.editTaskPriority(
                                                        task_priority
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-pencil-alt"
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "confirm",
                                                      rawName: "v-confirm",
                                                      value: {
                                                        ok: _vm.confirmTaskPriorityDelete(
                                                          task_priority
                                                        )
                                                      },
                                                      expression:
                                                        "{ok: confirmTaskPriorityDelete(task_priority)}"
                                                    },
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "task.delete_task_priority"
                                                      ),
                                                      expression:
                                                        "trans('task.delete_task_priority')"
                                                    }
                                                  ],
                                                  key:
                                                    "priority_" +
                                                    task_priority.id,
                                                  staticClass:
                                                    "btn btn-danger btn-sm"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-trash"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("pagination-record", {
                          attrs: {
                            "page-length":
                              _vm.filterTaskPriorityForm.page_length,
                            records: _vm.task_priorities
                          },
                          on: {
                            "update:pageLength": function($event) {
                              return _vm.$set(
                                _vm.filterTaskPriorityForm,
                                "page_length",
                                $event
                              )
                            },
                            "update:page-length": function($event) {
                              return _vm.$set(
                                _vm.filterTaskPriorityForm,
                                "page_length",
                                $event
                              )
                            },
                            updateRecords: _vm.getTaskPriorities
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-t-20" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("task.configuration")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.saveTaskConfiguration($event)
                            },
                            keydown: function($event) {
                              return _vm.taskConfigForm.errors.clear(
                                $event.target.name
                              )
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.trans("task.prefix")) + " "
                                      ),
                                      _c("show-tip", {
                                        attrs: {
                                          module: "task",
                                          tip: "tip_task_number_prefix",
                                          type: "field"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.taskConfigForm.task_number_prefix,
                                        expression:
                                          "taskConfigForm.task_number_prefix"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      value: "",
                                      name: "task_number_prefix",
                                      placeholder: _vm.trans("task.prefix")
                                    },
                                    domProps: {
                                      value:
                                        _vm.taskConfigForm.task_number_prefix
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.taskConfigForm,
                                          "task_number_prefix",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.taskConfigForm,
                                      "prop-name": "task_number_prefix"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.trans("task.digit")) + " "
                                      ),
                                      _c("show-tip", {
                                        attrs: {
                                          module: "task",
                                          tip: "tip_task_number_digit",
                                          type: "field"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.taskConfigForm.task_number_digit,
                                        expression:
                                          "taskConfigForm.task_number_digit"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      value: "",
                                      name: "task_number_digit",
                                      placeholder: _vm.trans("task.digit")
                                    },
                                    domProps: {
                                      value:
                                        _vm.taskConfigForm.task_number_digit
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.taskConfigForm,
                                          "task_number_digit",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.taskConfigForm,
                                      "prop-name": "task_number_digit"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "task.default_progress_type"
                                          )
                                        ) + " "
                                      ),
                                      _c("show-tip", {
                                        attrs: {
                                          module: "task",
                                          tip: "tip_task_progress_type",
                                          type: "field"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "radio radio-info" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.taskConfigForm
                                                .task_progress_type,
                                            expression:
                                              "taskConfigForm.task_progress_type"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "manual",
                                          id: "progress_type_manual",
                                          name: "task_progress_type"
                                        },
                                        domProps: {
                                          checked:
                                            _vm.taskConfigForm
                                              .task_progress_type === "manual",
                                          checked: _vm._q(
                                            _vm.taskConfigForm
                                              .task_progress_type,
                                            "manual"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.taskConfigForm,
                                              "task_progress_type",
                                              "manual"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          attrs: { for: "progress_type_manual" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trans(
                                                  "task.manual_progress"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "radio radio-info" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.taskConfigForm
                                                .task_progress_type,
                                            expression:
                                              "taskConfigForm.task_progress_type"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "sub_task",
                                          id: "progress_type_sub_task",
                                          name: "task_progress_type"
                                        },
                                        domProps: {
                                          checked:
                                            _vm.taskConfigForm
                                              .task_progress_type ===
                                            "sub_task",
                                          checked: _vm._q(
                                            _vm.taskConfigForm
                                              .task_progress_type,
                                            "sub_task"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.taskConfigForm,
                                              "task_progress_type",
                                              "sub_task"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "progress_type_sub_task"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trans(
                                                  "task.sub_task_progress"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "radio radio-info" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.taskConfigForm
                                                .task_progress_type,
                                            expression:
                                              "taskConfigForm.task_progress_type"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "question",
                                          id: "progress_type_question",
                                          name: "task_progress_type"
                                        },
                                        domProps: {
                                          checked:
                                            _vm.taskConfigForm
                                              .task_progress_type ===
                                            "question",
                                          checked: _vm._q(
                                            _vm.taskConfigForm
                                              .task_progress_type,
                                            "question"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.taskConfigForm,
                                              "task_progress_type",
                                              "question"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "progress_type_question"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trans("task.question")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.taskConfigForm,
                                      "prop-name": "task_progress_type"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("task.default_rating_type")
                                        ) + " "
                                      ),
                                      _c("show-tip", {
                                        attrs: {
                                          module: "task",
                                          tip: "tip_task_rating_type",
                                          type: "field"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "radio radio-info" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.taskConfigForm
                                                .task_rating_type,
                                            expression:
                                              "taskConfigForm.task_rating_type"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "task_based",
                                          id: "rating_type_task_based",
                                          name: "task_rating_type"
                                        },
                                        domProps: {
                                          checked:
                                            _vm.taskConfigForm
                                              .task_rating_type ===
                                            "task_based",
                                          checked: _vm._q(
                                            _vm.taskConfigForm.task_rating_type,
                                            "task_based"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.taskConfigForm,
                                              "task_rating_type",
                                              "task_based"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "rating_type_task_based"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trans(
                                                  "task.task_based_rating"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "radio radio-info" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.taskConfigForm
                                                .task_rating_type,
                                            expression:
                                              "taskConfigForm.task_rating_type"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "sub_task_based",
                                          id: "rating_type_sub_task_based",
                                          name: "task_rating_type"
                                        },
                                        domProps: {
                                          checked:
                                            _vm.taskConfigForm
                                              .task_rating_type ===
                                            "sub_task_based",
                                          checked: _vm._q(
                                            _vm.taskConfigForm.task_rating_type,
                                            "sub_task_based"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.taskConfigForm,
                                              "task_rating_type",
                                              "sub_task_based"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "rating_type_sub_task_based"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trans(
                                                  "task.sub_task_based_rating"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.taskConfigForm,
                                      "prop-name": "task_rating_type"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-info waves-effect waves-light",
                              attrs: { type: "submit" }
                            },
                            [_vm._v(_vm._s(_vm.trans("general.save")))]
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }